import { atom } from "jotai";
import useHttp from "../hooks/useHttp";

export const productsInfoAtom = atom(null);
export const productsLoadingStatusAtom = atom("idle");
export const filtersLoadingStatusAtom = atom("loading");
export const filterInfoAtom = atom(null);
export const periodAtom = atom("3M");
export const periodChangeFlagAtom = atom(false);
export const selectedFiltersAtom = atom({
    bw: [],
    vid: [],
    brnd: [],
    sf: [],
    bullet_type_s: [],
    bt: [],
    shell_length: [],
    mt: [],
    rpb: [],
});

export const fetchProductsAtom = atom(null, async (get, set, query) => {
    console.log(query)
    console.log(query.includes('period'))
    set(productsLoadingStatusAtom, "loading");
    set(filtersLoadingStatusAtom, "loading");
    const { getPriceHistory } = useHttp();
    getPriceHistory(query.includes('period') ? query : `${query}&period=3M`)
        .then((response) => {
            set(productsInfoAtom, response.response);
            set(filterInfoAtom, response.facets);
        })
        .finally(() => {
            set(productsLoadingStatusAtom, "idle");
            set(filtersLoadingStatusAtom, "idle");
        });
});
