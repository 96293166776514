import { Box, IconButton, Image, useColorMode } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import { getCurrentColor } from "../../constants";
import {imagesAtom} from "../../atom/groupListings";
import {useAtom} from "jotai";

const OneItemSlider = () => {
  const { colorMode } = useColorMode();

  const [images] = useAtom(imagesAtom);

  if(images && images.length > 0){
      return (
          <Box position="relative">
              <IconButton
                  className="custom-one-item-prev"
                  aria-label="Prev"
                  h={{ base: "35px", lg: "38px" }}
                  minW={{ base: "28px", lg: "31px" }}
                  icon={
                      <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                          <path
                              d="M3.91592 8.00179L9.88965 14.222C10.0397 14.3757 10.0361 14.6293 9.88251 14.7866L8.81424 15.8798C8.66061 16.037 8.41051 16.0406 8.26045 15.887L0.964779 8.28761C0.886178 8.20901 0.850449 8.10182 0.857595 7.99821C0.854022 7.89103 0.88975 7.78742 0.964779 7.70881L8.26046 0.113025C8.41051 -0.0406057 8.66061 -0.0370351 8.81424 0.12017L9.88251 1.21345C10.0361 1.37065 10.0397 1.62432 9.88966 1.77795L3.91592 8.00179Z"
                              fill={getCurrentColor(colorMode, "#000000", "#ffffff")}
                          />
                      </svg>
                  }
                  position="absolute"
                  top="50%"
                  left="0"
                  transform="translateY(-50%)"
                  zIndex="10"
                  borderTopRightRadius="2px"
                  borderBottomRightRadius="2px"
                  borderTopLeftRadius="0"
                  borderBottomLeftRadius="0"
                  border="1px solid"
                  borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
                  bg={getCurrentColor(colorMode, "blueC.900", "grayC.1700")}
                  _hover={{
                      bg: getCurrentColor(colorMode, "blueC.900", "grayC.1700"),
                  }}
              />

              <IconButton
                  className="custom-one-item-next"
                  aria-label="Next"
                  h={{ base: "35px", lg: "38px" }}
                  minW={{ base: "28px", lg: "31px" }}
                  icon={
                      <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                          <path
                              d="M6.08408 7.99821L0.110346 1.77795C-0.0397123 1.62432 -0.0361395 1.37065 0.117491 1.21345L1.18576 0.120171C1.33939 -0.037033 1.58949 -0.0406058 1.73955 0.113025L9.03522 7.71239C9.11382 7.79099 9.14955 7.89818 9.1424 8.00179C9.14598 8.10897 9.11025 8.21258 9.03522 8.29119L1.73954 15.887C1.58949 16.0406 1.33939 16.037 1.18576 15.8798L0.117489 14.7866C-0.0361418 14.6293 -0.0397145 14.3757 0.110343 14.222L6.08408 7.99821Z"
                              fill={getCurrentColor(colorMode, "#000000", "#ffffff")}
                          />
                      </svg>
                  }
                  position="absolute"
                  top="50%"
                  right="0"
                  transform="translateY(-50%)"
                  zIndex="10"
                  borderTopLeftRadius="2px"
                  borderBottomLeftRadius="2px"
                  borderTopRightRadius="0"
                  borderBottomRightRadius="0"
                  border="1px solid"
                  borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
                  bg={getCurrentColor(colorMode, "blueC.900", "grayC.1700")}
                  _hover={{
                      bg: getCurrentColor(colorMode, "blueC.900", "grayC.1700"),
                  }}
              />
              <Swiper
                  navigation={{
                      prevEl: ".custom-one-item-prev",
                      nextEl: ".custom-one-item-next",
                  }}
                  modules={[Navigation]}
              >
                  {images[0]?.image1 &&
                      <SwiperSlide>
                          <Image
                              border="1px solid"
                              borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
                              src={images[0].image1}
                              rounded="md"
                              alt=""
                          />
                      </SwiperSlide>
                  }
                  {images[0]?.image2 &&
                      <SwiperSlide>
                          <Image
                              border="1px solid"
                              borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
                              src={images[0].image2}
                              rounded="md"
                              alt=""
                          />
                      </SwiperSlide>
                  }
                  {images[0]?.image3 &&
                      <SwiperSlide>
                          <Image
                              border="1px solid"
                              borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
                              src={images[0].image3}
                              rounded="md"
                              alt=""
                          />
                      </SwiperSlide>
                  }
              </Swiper>
          </Box>
      )
  }
};

export default OneItemSlider;
