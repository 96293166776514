import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import useSearchParamsHandler from "./useSearchParamsHandler";
import {
    fetchProductsAtom, periodAtom,
    productsInfoAtom,
    productsLoadingStatusAtom,
} from "../atom/priceHistory";
import { useAtom } from "jotai";

const useUpdateGroupListings = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { params } = useSearchParamsHandler();
    const [, fetchProducts] = useAtom(fetchProductsAtom);
    const [productsLoadingStatus, setProductsLoadingStatus] = useAtom(
        productsLoadingStatusAtom,
    );
    const [productsInfo] = useAtom(productsInfoAtom);

    const removeFromSearch = useCallback(() => {
        const fetchedParams = new URLSearchParams(location.search);
        fetchedParams.delete("sf");
        fetchedParams.delete("rounds");
        return fetchedParams.toString();
    }, [location.search]);

    const settingProducts = useCallback(async (location) => {
        try {
            setProductsLoadingStatus("loading");
            await fetchProducts(location);
        } catch (e) {
            console.error(e);
        } finally {
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const updatedSearch = removeFromSearch();
        settingProducts(updatedSearch.startsWith("?") ? updatedSearch.slice(1) : updatedSearch);

        return;
        // eslint-disable-next-line
    }, [searchParams]);

    const data = useMemo(
        () => ({
            productsInfo,
            productsLoadingStatus,
        }),
        [
            productsInfo,
            productsLoadingStatus,
        ],
    );

    return data;
};

export default useUpdateGroupListings;
