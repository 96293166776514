import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  Text,
  FormErrorMessage,
  FormControl,
  useColorMode,
  FormLabel,
  Divider,
  InputLeftElement,
  Radio,
  RadioGroup,
  Textarea,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import {useEffect, useState} from "react";
import {
  FormEmailFillIcon,
  FormLocationFillIcon,
  FormPhoneFillIcon,
  FormWorldFillIcon,
} from "../assets/icons/icons";
import { emailReg, getCurrentColor } from "../constants";
import useHttp from "../hooks/useHttp";
import SuccessFormSend from "../components/SuccessFormSend/SuccessFormSend";
import ErrorFormSend from "../components/ErrorFormSend/ErrorFormSend";

const VendorRegistration = () => {
  const { colorMode } = useColorMode();

  const { postVendorForm } = useHttp();

  const [error] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (values) => {
    postVendorForm({
      ...values,
      interested_in_targeted_email_campaigns_for_your_products: values.interested_in_targeted_email_campaigns_for_your_products === 'yes',
      interested_in_featured_product_placement: values.interested_in_featured_product_placement === 'yes'
    }).then((res)=> {
      if (res.code === "ERR_NETWORK" || res.code === "ERR_BAD_REQUEST" || res.code === "ERR_BAD_RESPONSE") {
        setIsError(true)
      } else if (res.status === 200) {
        setIsSuccessful(true);
      }
    });
  }

  if(isSuccessful && !isError){
    return (
    <Flex
        bg={getCurrentColor(colorMode, "whiteC.200", "blueC.500")}
        pt={{ base: "38px", md: "42px" }}
        pb={{ base: "58px", md: "81px" }}
        px="21px"
    >
      <SuccessFormSend setSuccess={setIsSuccessful} />
    </Flex>
    )
  }

  if(isError && !isSuccessful){
    return (
        <Flex
            bg={getCurrentColor(colorMode, "whiteC.200", "blueC.500")}
            pt={{ base: "38px", md: "42px" }}
            pb={{ base: "58px", md: "81px" }}
            px="21px"
        >
          <ErrorFormSend setError={setIsError} />
        </Flex>
    )
  }

  return (
    <Flex
      bg={getCurrentColor(colorMode, "whiteC.200", "blueC.500")}
      pt={{ base: "38px", md: "42px" }}
      pb={{ base: "58px", md: "81px" }}
      px="21px"
    >
      <Helmet>
        <title>Vendor Registration to Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      <Box maxW="752px" w="100%" mx="auto">
        <Heading
          as="h2"
          fontSize="25px"
          mb={{ base: "33px", md: "55px" }}
          fontWeight="600"
          fontFamily="mainFont"
          textAlign="center"
          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
        >
          Vendor Registration
        </Heading>

        {error && (
          <Text color="red.500" mb="18px">
            {error}
          </Text>
        )}

        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Text
            mb={{ base: "18px", md: 6 }}
            fontSize="lg"
            fontWeight="600"
            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
          >
            Contact Information
          </Text>

          <Flex
            rowGap={5}
            columnGap="10px"
            mb={5}
            direction={{ base: "column", md: "row" }}
          >
            <FormControl isInvalid={errors.business_name} position="relative">
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Business name
              </FormLabel>
              <Input
                placeholder="Enter your company’s name"
                border="1px solid"
                bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.3400",
                  "grayC.2400",
                )}
                _placeholder={{
                  color: getCurrentColor(
                    colorMode,
                    "blackC.2100",
                    "grayC.2600",
                  ),
                }}
                opacity={colorMode === "dark" && "70%"}
                focusBorderColor="grayC.1300"
                fontSize="15px"
                px="13px"
                py="15px"
                h="50px"
                id="business_name"
                name="business_name"
                {...register("business_name", {
                  required: "This is required",
                  minLength: {
                    value: 1,
                    message: "Minimum length should be 2",
                  },
                })}
              />

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.business_name && errors.business_name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.contact_name} position="relative">
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Contact name
              </FormLabel>
              <Input
                placeholder="First and last name of primary contact"
                border="1px solid"
                bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.3400",
                  "grayC.2400",
                )}
                _placeholder={{
                  color: getCurrentColor(
                    colorMode,
                    "blackC.2100",
                    "grayC.2600",
                  ),
                }}
                opacity={colorMode === "dark" && "70%"}
                focusBorderColor="grayC.1300"
                fontSize="15px"
                px="13px"
                py="15px"
                h="50px"
                id="contact_name"
                name="contact_name"
                {...register("contact_name", {
                  required: "This is required",
                  minLength: {
                    value: 1,
                    message: "Minimum length should be 2",
                  },
                })}
              />

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.contact_name && errors.contact_name.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex
            rowGap={5}
            columnGap="10px"
            mb={{ base: "25px", md: "30px" }}
            direction={{ base: "column", md: "row" }}
          >
            <FormControl isInvalid={errors.email_address} position="relative">
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Email address
              </FormLabel>

              <InputGroup>
                <Input
                  placeholder="Primary email for communication"
                  border="1px solid"
                  bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                  borderColor={getCurrentColor(
                    colorMode,
                    "grayC.3400",
                    "grayC.2400",
                  )}
                  _placeholder={{
                    color: getCurrentColor(
                      colorMode,
                      "blackC.2100",
                      "grayC.2600",
                    ),
                  }}
                  opacity={colorMode === "dark" && "70%"}
                  focusBorderColor="grayC.1300"
                  fontSize="15px"
                  px="13px"
                  py="15px"
                  h="50px"
                  id="email_address"
                  name="email_address"
                  {...register("email_address", {
                    required: "This is required",
                    pattern: {
                      value: emailReg,
                      message: "Invalid email address",
                    },
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <InputLeftElement width="43px" top="5px">
                  <FormEmailFillIcon
                    fill={getCurrentColor(colorMode, "#1A1A1A", "#AFAFAF")}
                    opacity={getCurrentColor(colorMode, "0.4", "0.5")}
                  />
                </InputLeftElement>
              </InputGroup>

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.email_address && errors.email_address.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.phone_no} position="relative">
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Phone number
              </FormLabel>
              <InputGroup>
                <Input
                  placeholder="Preferred contact number"
                  border="1px solid"
                  bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                  borderColor={getCurrentColor(
                    colorMode,
                    "grayC.3400",
                    "grayC.2400",
                  )}
                  _placeholder={{
                    color: getCurrentColor(
                      colorMode,
                      "blackC.2100",
                      "grayC.2600",
                    ),
                  }}
                  opacity={colorMode === "dark" && "70%"}
                  focusBorderColor="grayC.1300"
                  fontSize="15px"
                  px="13px"
                  py="15px"
                  h="50px"
                  id="phone_no"
                  name="phone_no"
                  {...register("phone_no", {
                    required: "This is required",
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <InputLeftElement width="39px" top="5px">
                  <FormPhoneFillIcon
                    fill={getCurrentColor(colorMode, "#1A1A1A", "#AFAFAF")}
                    opacity={getCurrentColor(colorMode, "0.4", "0.5")}
                  />
                </InputLeftElement>
              </InputGroup>

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.phone_number && errors.phone_number.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Divider
            color={getCurrentColor(colorMode, "grayC.3400", "grayC.3600")}
            mb={{ base: "25px", md: "30px" }}
          />

          <Text
            mb={{ base: "18px", md: 6 }}
            fontSize="lg"
            fontWeight="600"
            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
          >
            Business Details
          </Text>

          <Flex
            rowGap={5}
            columnGap="10px"
            mb={5}
            direction={{ base: "column", md: "row" }}
          >
            <FormControl isInvalid={errors.website_url} position="relative">
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Website URL
              </FormLabel>

              <InputGroup>
                <Input
                  placeholder="Enter your website link"
                  border="1px solid"
                  bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                  borderColor={getCurrentColor(
                    colorMode,
                    "grayC.3400",
                    "grayC.2400",
                  )}
                  _placeholder={{
                    color: getCurrentColor(
                      colorMode,
                      "blackC.2100",
                      "grayC.2600",
                    ),
                  }}
                  opacity={colorMode === "dark" && "70%"}
                  focusBorderColor="grayC.1300"
                  fontSize="15px"
                  px="13px"
                  py="15px"
                  h="50px"
                  id="website_url"
                  name="website_url"
                  {...register("website_url", {
                    required: "This is required",
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <InputLeftElement width="41px" top="5px">
                  <FormWorldFillIcon
                    fill={getCurrentColor(colorMode, "#1A1A1A", "#AFAFAF")}
                    opacity={getCurrentColor(colorMode, "0.4", "0.5")}
                  />
                </InputLeftElement>
              </InputGroup>

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.website_url && errors.website_url.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={errors.ship_from_location}
              position="relative"
            >
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Ship From Location
              </FormLabel>
              <InputGroup>
                <Input
                  placeholder="City, state and country"
                  border="1px solid"
                  bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                  borderColor={getCurrentColor(
                    colorMode,
                    "grayC.3400",
                    "grayC.2400",
                  )}
                  _placeholder={{
                    color: getCurrentColor(
                      colorMode,
                      "blackC.2100",
                      "grayC.2600",
                    ),
                  }}
                  opacity={colorMode === "dark" && "70%"}
                  focusBorderColor="grayC.1300"
                  fontSize="15px"
                  px="13px"
                  py="15px"
                  h="50px"
                  id="ship_from_location"
                  name="ship_from_location"
                  {...register("ship_from_location", {
                    required: "This is required",
                    minLength: {
                      value: 1,
                      message: "Minimum length should be 2",
                    },
                  })}
                />
                <InputLeftElement width="37px" top="5px">
                  <FormLocationFillIcon
                    fill={getCurrentColor(colorMode, "#1A1A1A", "#AFAFAF")}
                    opacity={getCurrentColor(colorMode, "0.4", "0.5")}
                  />
                </InputLeftElement>
              </InputGroup>

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.ship_from_location && errors.ship_from_location.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl
            isInvalid={errors.primary_product_categories}
            mb={{ base: "25px", md: "30px" }}
            position="relative"
          >
            <FormLabel
              fontWeight="400"
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
            >
              Primary product categories
            </FormLabel>

            <Input
              placeholder="List the main categories of ammunition or related products you offer"
              border="1px solid"
              bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.3400",
                "grayC.2400",
              )}
              _placeholder={{
                color: getCurrentColor(colorMode, "blackC.2100", "grayC.2600"),
              }}
              opacity={colorMode === "dark" && "70%"}
              focusBorderColor="grayC.1300"
              fontSize="15px"
              px="13px"
              py="15px"
              h="50px"
              id="primary_product_categories"
              name="primary_product_categories"
              {...register("primary_product_categories", {
                required: "This is required",
                minLength: {
                  value: 1,
                  message: "Minimum length should be 2",
                },
              })}
            />

            <FormErrorMessage
              position="absolute"
              bottom={-4}
              left={0}
              fontSize="xs"
            >
              {errors.email_address && errors.email_address.message}
            </FormErrorMessage>
          </FormControl>

          <Divider
            color={getCurrentColor(colorMode, "grayC.3400", "grayC.3600")}
            mb={{ base: "25px", md: "30px" }}
          />

          <Text
            mb={{ base: "18px", md: 6 }}
            fontSize="lg"
            fontWeight="600"
            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
          >
            Integration Details
          </Text>

          <Flex
            rowGap={5}
            columnGap="10px"
            mb={{ base: "25px", md: "30px" }}
            direction={{ base: "column", md: "row" }}
          >
            <FormControl
              isInvalid={errors.current_data_feed_provider}
              position="relative"
            >
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Current data feed provider
              </FormLabel>

              <Input
                placeholder="Please specify whether internal or third-party"
                border="1px solid"
                bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.3400",
                  "grayC.2400",
                )}
                _placeholder={{
                  color: getCurrentColor(
                    colorMode,
                    "blackC.2100",
                    "grayC.2600",
                  ),
                }}
                opacity={colorMode === "dark" && "70%"}
                focusBorderColor="grayC.1300"
                fontSize="15px"
                px="13px"
                py="15px"
                h="50px"
                id="current_data_feed_provider"
                name="current_data_feed_provider"
                {...register("current_data_feed_provider", {
                  required: "This is required",
                  minLength: {
                    value: 1,
                    message: "Minimum length should be 2",
                  },
                })}
              />

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.current_data_feed_provider &&
                  errors.current_data_feed_provider.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={errors.affiliate_program_provider}
              position="relative"
            >
              <FormLabel
                fontWeight="400"
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              >
                Affiliate program provider
              </FormLabel>
              <Input
                placeholder="Please specify any Affiliate Programs utilized"
                border="1px solid"
                bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.3400",
                  "grayC.2400",
                )}
                _placeholder={{
                  color: getCurrentColor(
                    colorMode,
                    "blackC.2100",
                    "grayC.2600",
                  ),
                }}
                opacity={colorMode === "dark" && "70%"}
                focusBorderColor="grayC.1300"
                fontSize="15px"
                px="13px"
                py="15px"
                h="50px"
                id="affiliate_program_provider"
                name="affiliate_program_provider"
                {...register("affiliate_program_provider", {
                  required: "This is required",
                  minLength: {
                    value: 1,
                    message: "Minimum length should be 2",
                  },
                })}
              />

              <FormErrorMessage
                position="absolute"
                bottom={-4}
                left={0}
                fontSize="xs"
              >
                {errors.affiliate_program_provider &&
                  errors.affiliate_program_provider.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Divider
            color={getCurrentColor(colorMode, "grayC.3400", "grayC.3600")}
            mb={{ base: "25px", md: "30px" }}
          />

          <Text
            mb={{ base: "18px", md: 6 }}
            fontSize="lg"
            fontWeight="600"
            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
          >
            Marketing and Exposure
          </Text>

          <FormControl
            isInvalid={errors.interested_in_featured_product_placement}
            mb={6}
            position="relative"
          >
            <FormLabel
              fontWeight="500"
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              mb="15px"
            >
              Would you like to explore featured product placements?
            </FormLabel>

            <Controller
              name="interested_in_featured_product_placement"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <RadioGroup
                  name={field.name}
                  value={field.value}
                  fontSize="15px"
                  id="explore_featured_product"
                  onChange={field.onChange}
                  color={getCurrentColor(colorMode, "blackC.100", "grayC.2600")}
                >
                  <Flex columnGap="30px">
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Flex>
                </RadioGroup>
              )}
            />

            <FormErrorMessage
              position="absolute"
              bottom={-4}
              left={0}
              fontSize="xs"
            >
              {errors.interested_in_featured_product_placement &&
                errors.interested_in_featured_product_placement.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errors.interested_in_targeted_email_campaigns_for_your_products}
            mb="25px"
            position="relative"
          >
            <FormLabel
              fontWeight="500"
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              mb="15px"
            >
              Are you interested in targeted email campaigns for your products?
            </FormLabel>

            <Controller
              name="interested_in_targeted_email_campaigns_for_your_products"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <RadioGroup
                  name={field.name}
                  value={field.value}
                  fontSize="15px"
                  id="interested_in_targeted_email_campaigns_for_your_products"
                  onChange={field.onChange}
                  color={getCurrentColor(colorMode, "blackC.100", "grayC.2600")}
                >
                  <Flex columnGap="30px">
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Flex>
                </RadioGroup>
              )}
            />

            <FormErrorMessage
              position="absolute"
              bottom={-4}
              left={0}
              fontSize="xs"
            >
              {errors.interested_in_targeted_email_campaigns_for_your_products &&
                errors.interested_in_targeted_email_campaigns_for_your_products.message}
            </FormErrorMessage>
          </FormControl>

          <Divider
            color={getCurrentColor(colorMode, "grayC.3400", "grayC.3600")}
            mb="25px"
          />

          <FormControl
            isInvalid={errors.questions_comments}
            position="relative"
            mb={{ base: "25px", md: "30px" }}
          >
            <FormLabel
              fontWeight="600"
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
            >
              Questions or Comments:
            </FormLabel>
            <Textarea
              placeholder="Any additional questions, special requirements, or information you'd like to share"
              border="1px solid"
              bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.3400",
                "grayC.2400",
              )}
              _placeholder={{
                color: getCurrentColor(colorMode, "blackC.2100", "grayC.2600"),
              }}
              opacity={colorMode === "dark" && "70%"}
              focusBorderColor="grayC.1300"
              fontSize="15px"
              px="15px"
              py="13px"
              id="questions_comments"
              name="questions_comments"
              {...register("questions_comments")}
            />

            <FormErrorMessage
              position="absolute"
              bottom={-4}
              left={0}
              fontSize="xs"
            >
              {errors.questions_comments &&
                errors.questions_comments.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            bg="blueC.400"
            _hover={{ bg: "blueC.200" }}
            color="whiteC.100"
            type="submit"
            borderRadius="6px"
            fontWeight="500"
            w={{ base: "100%", md: "206px" }}
            isLoading={isSubmitting}
            h="50px"
          >
            Submit
          </Button>
        </form>
      </Box>
    </Flex>
  );
};

export default VendorRegistration;
