import { Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import "swiper/css/navigation";
import { memo } from "react";
import "swiper/css";
import ProductAttributes from "../ProductAttributes/ProductAttributes";
import OneItemSlider from "../Sliders/OneItemSlider";
import { InfoIcon } from "../../assets/icons/icons";
import { getCurrentColor } from "../../constants";
import Tooltip from "../ToolTip/Tooltip";

const ItemInfoDesktop = () => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      flexDirection={"column"}
      display={{ base: "none", lg: "block" }}
      maxW={{ base: "270px", xl: "400px" }}
      w={"100%"}
    >
      <Box mb="30px">
        <OneItemSlider />
      </Box>

      <Box mb="30px">
        <Flex mb={3} alignItems="center">
          <Text fontWeight="600" fontSize="lg" pr={2}>
            Product Attributes
          </Text>
          <Tooltip text="These attributes are provided by multiple sources and may not be accurate.  Always confirm product details on the vendor's site before purchasing">
            <InfoIcon />
          </Tooltip>
        </Flex>
        <Box
          borderRadius="5px"
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
          border="1px solid"
          borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
          p="19px"
        >
          <ProductAttributes />
        </Box>
      </Box>
    </Flex>
  );
};

export default memo(ItemInfoDesktop);
