export const transformUserData = (userData) =>
  userData.reduce((acc, item) => {
    switch (item.Name) {
      case "given_name":
        return { ...acc, given_name: item.Value };
      case "family_name":
        return { ...acc, family_name: item.Value };
      case "email":
        return { ...acc, email: item.Value };
      case "sub":
        return { ...acc, id: item.Value };
      default:
        return acc;
    }
  }, {});
