import { Button, Flex, Image, Text, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";

const SuccessFormSend = ({ setSuccess }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      w={"100%"}
      h={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Text
        mb={"10px"}
        color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
      >
        Form sending completed
      </Text>

      <Text color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}>
        We will contact you soon
      </Text>
      <Image
        src="https://cdn.bulletscout.com/image/send-message.webp"
        alt="send-message"
        w={"350px"}
      />
      <Button mt={"20px"} onClick={() => setSuccess(false)}>
        Send another one
      </Button>
    </Flex>
  );
};

export default SuccessFormSend;
