const isNumeric = (str) => !isNaN(str) && !isNaN(parseFloat(str));

const compareItems = (a, b, key) => {
  const aValue = a[key];
  const bValue = b[key];

  const aIsNumeric = isNumeric(aValue);
  const bIsNumeric = isNumeric(bValue);

  if (aIsNumeric && bIsNumeric) {
    return parseFloat(aValue) - parseFloat(bValue);
  } else if (aIsNumeric) {
    return -1;
  } else if (bIsNumeric) {
    return 1;
  } else {
    return aValue.localeCompare(bValue);
  }
};

const getSortKey = (item) => {
  const keys = ["alt_name", "flag", "name", "brand_name", "vendor_name"];
  return keys.find((key) => item[key] !== undefined);
};

export const sortData = (arr) =>
  [...arr].sort((a, b) => {
    const keyA = getSortKey(a);
    const keyB = getSortKey(b);

    if (!keyA || !keyB) {
      return 0;
    }

    return compareItems(a, b, keyA);
  });
