import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, useColorMode } from "@chakra-ui/react";

import { getCurrentColor } from "../../constants";
import {allPagesAtom, categoriesPageModeAtom} from "../../atom/listings";
import { allPagesAtom as allProductsPagesAtom } from "../../atom/products";
import { allPagesAtom as allGroupListingsPagesAtom } from '../../atom/groupListings';
import { useAtom } from "jotai";
import { useSearchParams } from "react-router-dom";
import useSearchParamsHandler from "../../hooks/useSearchParamsHandler";

const Pagination = ({ slider }) => {
  const { params } = useSearchParamsHandler();
  const [categoriesPageMode] = useAtom(categoriesPageModeAtom);

  const isListingsMode = categoriesPageMode === "listings";
  const [allPages] = useAtom(slider ? allGroupListingsPagesAtom : isListingsMode ? allPagesAtom : allProductsPagesAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = +searchParams.get("page");


  const { colorMode } = useColorMode();
  const renderTabsWithDots = () => {
    return (
      <>
        <Flex
          onClick={() =>
            setSearchParams({
              ...params,
              page: currentPage > allPages - 3 ? allPages - 3 : currentPage,
            })
          }
          px={"3px"}
          justifyContent={"center"}
          alignItems={"center"}
          border={"1px solid"}
          borderColor={
            currentPage < allPages - 2
              ? getCurrentColor(colorMode, "blueC.100", "blueC.400")
              : getCurrentColor(colorMode, "grayC.1300", "grayC.2400")
          }
          color={
            currentPage < allPages - 2
              ? getCurrentColor(colorMode, "blueC.100", "whiteC.100")
              : getCurrentColor(colorMode, "blackC.700", "whiteC.100")
          }
          borderRadius={"4px"}
          w={{ base: "30px", "1xs": "40px", md: "32px" }}
          h={{ base: "30px", "1xs": "40px", md: "32px" }}
          cursor={"pointer"}
          bg={
            currentPage < allPages - 2
              ? getCurrentColor(colorMode, "transparent", "blueC.400")
              : "transparent"
          }
        >
          {currentPage < allPages - 3 ? currentPage : allPages - 3}
        </Flex>

        {allPages > 1 && (
          <Flex
            cursor={"pointer"}
            px={"3px"}
            onClick={() =>
              setSearchParams({
                ...params,
                page:
                  currentPage > allPages - 2 ? allPages - 2 : currentPage + 1,
              })
            }
            justifyContent={"center"}
            alignItems={"center"}
            border={"1px solid"}
            borderColor={
              currentPage === allPages - 2
                ? getCurrentColor(colorMode, "blueC.100", "blueC.400")
                : getCurrentColor(colorMode, "grayC.1300", "grayC.2400")
            }
            color={
              currentPage === allPages - 2
                ? getCurrentColor(colorMode, "blueC.100", "whiteC.100")
                : getCurrentColor(colorMode, "blackC.700", "whiteC.100")
            }
            borderRadius={"4px"}
            minW={{ base: "30px", "1xs": "40px", md: "32px" }}
            h={{ base: "30px", "1xs": "40px", md: "32px" }}
            bg={
              currentPage === allPages - 2
                ? getCurrentColor(colorMode, "transparent", "blueC.400")
                : "transparent"
            }
          >
            {currentPage < allPages - 2 ? currentPage + 1 : allPages - 2}
          </Flex>
        )}

        {allPages > 4 && (
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            border={"1px solid"}
            borderColor={getCurrentColor(colorMode, "grayC.1300", "grayC.2400")}
            color={getCurrentColor(colorMode, "blackC.700", "whiteC.100")}
            opacity={currentPage === allPages ? "0.5" : "1"}
            borderRadius={"4px"}
            w={{ base: "30px", "1xs": "40px", md: "32px" }}
            h={{ base: "30px", "1xs": "40px", md: "32px" }}
            bg={"transparent"}
          >
            {"..."}
          </Flex>
        )}

        {allPages > 3 && (
          <Flex
            px={"3px"}
            cursor={"pointer"}
            onClick={() =>
              setSearchParams({
                ...params,
                page: allPages - 1,
              })
            }
            justifyContent={"center"}
            alignItems={"center"}
            border={"1px solid"}
            borderRadius={"4px"}
            minW={{ base: "30px", "1xs": "40px", md: "32px" }}
            h={{ base: "30px", "1xs": "40px", md: "32px" }}
            borderColor={
              currentPage === allPages - 1
                ? getCurrentColor(colorMode, "blueC.100", "blueC.400")
                : getCurrentColor(colorMode, "grayC.1300", "grayC.2400")
            }
            color={
              currentPage === allPages - 1
                ? getCurrentColor(colorMode, "blueC.100", "whiteC.100")
                : getCurrentColor(colorMode, "blackC.700", "whiteC.100")
            }
            bg={
              currentPage === allPages - 1
                ? getCurrentColor(colorMode, "transparent", "blueC.400")
                : "transparent"
            }
          >
            {allPages - 1}
          </Flex>
        )}

        {allPages > 2 && (
          <Flex
            px={"3px"}
            cursor={"pointer"}
            onClick={() =>
              setSearchParams({
                ...params,
                page: allPages,
              })
            }
            justifyContent={"center"}
            alignItems={"center"}
            border={"1px solid"}
            borderColor={
              currentPage === allPages
                ? getCurrentColor(colorMode, "blueC.100", "blueC.400")
                : getCurrentColor(colorMode, "grayC.1300", "grayC.2400")
            }
            color={
              currentPage === allPages
                ? getCurrentColor(colorMode, "blueC.100", "whiteC.100")
                : getCurrentColor(colorMode, "blackC.700", "whiteC.100")
            }
            borderRadius={"4px"}
            minW={{ base: "30px", "1xs": "40px", md: "32px" }}
            h={{ base: "30px", "1xs": "40px", md: "32px" }}
            bg={
              currentPage === allPages
                ? getCurrentColor(colorMode, "transparent", "blueC.400")
                : "transparent"
            }
          >
            {allPages}
          </Flex>
        )}
      </>
    );
  };

  const renderTabsWithoutDots = () => {
    const pageNumbers = Array.from(
      { length: allPages },
      (_, index) => index + 1,
    );
    return pageNumbers.map((page) => (
      <Flex
        cursor={"pointer"}
        key={page}
        onClick={() =>
          setSearchParams({
            ...params,
            page: page,
          })
        }
        justifyContent={"center"}
        alignItems={"center"}
        border={"1px solid"}
        bg={
          currentPage === page
            ? getCurrentColor(colorMode, "transparent", "blueC.400")
            : "transparent"
        }
        borderColor={
          currentPage === page
            ? getCurrentColor(colorMode, "blueC.100", "blueC.400")
            : "grayC.1300"
        }
        color={
          currentPage === page
            ? getCurrentColor(colorMode, "blueC.100", "whiteC.100")
            : getCurrentColor(colorMode, "blackC.700", "whiteC.100")
        }
        borderRadius={"4px"}
        minW={{ base: "30px", "1xs": "40px", md: "32px" }}
        h={{ base: "30px", "1xs": "40px", md: "32px" }}
      >
        {page}
      </Flex>
    ));
  };

  return (
    <Flex mx={"auto"} gap={"8px"}>
      <Flex
        cursor={currentPage > 1 ? "pointer" : "auto"}
        justifyContent={"center"}
        alignItems={"center"}
        onClick={() =>
          {
              if(currentPage === 1) return;
              setSearchParams({
                  ...params,
                  page: currentPage > 1 && currentPage - 1,
              })
          }
        }
        border={"1px solid"}
        borderColor={getCurrentColor(colorMode, "grayC.1300", "transparent")}
        bg={
          currentPage === 1
            ? getCurrentColor(colorMode, "grayC.700", "grayC.1700")
            : getCurrentColor(colorMode, "whiteC.100", "grayC.1700")
        }
        opacity={currentPage === 1 ? "0.5" : "1"}
        borderRadius={"4px"}
        minW={{ base: "30px", "1xs": "40px", md: "32px" }}
        h={{ base: "30px", "1xs": "40px", md: "32px" }}
      >
        <ChevronLeftIcon
          h={"25px"}
          w={"25px"}
          color={
            currentPage === 1
              ? "grayC.800"
              : getCurrentColor(colorMode, "grayC.1300", "grayC.1600")
          }
        />
      </Flex>

      {allPages > 4 ? renderTabsWithDots() : renderTabsWithoutDots()}

      <Flex
        cursor={currentPage >= allPages ? "default" : "pointer"}
        onClick={() =>
          {
              if(currentPage >= allPages) return
              setSearchParams({
                  ...params,
                  page: currentPage < allPages && currentPage + 1,
              })
          }
        }
        justifyContent={"center"}
        alignItems={"center"}
        border={"1px solid"}
        borderColor={getCurrentColor(colorMode, "grayC.1300", "transparent")}
        bg={
          currentPage === allPages
            ? getCurrentColor(colorMode, "grayC.700", "grayC.1700")
            : getCurrentColor(colorMode, "whiteC.100", "grayC.1700")
        }
        opacity={currentPage === allPages ? "0.5" : "1"}
        borderRadius={"4px"}
        minW={{ base: "30px", "1xs": "40px", md: "32px" }}
        h={{ base: "30px", "1xs": "40px", md: "32px" }}
      >
        <ChevronRightIcon
          h={"25px"}
          w={"25px"}
          color={
            currentPage === allPages
              ? "grayC.800"
              : getCurrentColor(colorMode, "grayC.1300", "grayC.1600")
          }
        />
      </Flex>
    </Flex>
  );
};
export default Pagination;
