import React from 'react';
import ProductList from "./ProductList";
import useUpdateProducts from "../../hooks/useUpdateProducts";

const ProductsList = () => {
    const { currentPage, allPages, productsInfo, productsLoadingStatus } = useUpdateProducts();
    return (
        <ProductList currentPage={currentPage} allPages={allPages} productsInfo={productsInfo} productsLoadingStatus={productsLoadingStatus} />
    );
};

export default ProductsList;