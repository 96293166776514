import React from 'react';
import ProductList from "./ProductList";
import useUpdateGroupListings from "../../hooks/useUpdateGroupListings";

const ProductGroupList = ({slider}) => {
    const { currentPage, allPages, productsInfo, productsLoadingStatus } = useUpdateGroupListings();
    return (
        <ProductList slider={slider} currentPage={currentPage} allPages={allPages} productsInfo={productsInfo} productsLoadingStatus={productsLoadingStatus}/>
    );
};

export default ProductGroupList;