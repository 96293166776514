import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSearchParamsHandler from "../../hooks/useSearchParamsHandler";
import useCookie from "../../hooks/useCookie";
import SearchInput from "../SearchInput/SearchInput";

const ShipTo = ({ setShowLocation, showLocation }) => {
  const { cookies, setMyCookie, removeMyCookie } = useCookie();
  const [zipCode, setZipCode] = useState(cookies?.zip ? cookies.zip : "");
  const [searchParams, setSearchParams] = useSearchParams();
  const { params, addOrUpdateParam, deleteParam } = useSearchParamsHandler();
  const [zipCodeError, setZipCodeError] = useState(false);

  const getZipCode = (zipCode) => {
    setZipCodeError(false);
    if (zipCode.length === 0) {
      setZipCodeError(true);
      return;
    }
    if (!cookies.zip && zipCode) {
      addOrUpdateParam("zip", zipCode);
      setMyCookie("zip", zipCode, "/");
    } else if (
      cookies?.zip !== zipCode &&
      searchParams.get("zip") !== zipCode
    ) {
      setSearchParams({ ...params, zip: zipCode || cookies.zip });
      removeMyCookie("zip", "/");
      setMyCookie("zip", zipCode, "/");
    } else if (searchParams.get("zip") && !cookies.zip) {
      deleteParam("zip", searchParams.get("zip"));
    } else if (cookies.zip && !searchParams.get("zip")) {
      addOrUpdateParam("zip", cookies.zip);
    }
  };

  const clearCookies = () => {
    removeMyCookie("zip", "/");
    setZipCode("");
    deleteParam("zip", searchParams.get("zip"));
  };

  return (
    <>
      <Flex
        flexDirection={"column"}
        w={"100%"}
        mb={4}
        display={{ base: "block", lg: "flex" }}
      >
        <Heading
          as="h3"
          fontSize={"lg"}
          display={{ base: "none", lg: "block" }}
          w={"100%"}
          mb={3}
        >
          Add zip code
        </Heading>
        <Box position={"relative"} w={"100%"} mb={{ base: 0, lg: 3 }}>
          <Box position={"relative"} zIndex={1}>
            <SearchInput
              w={"100%"}
              icon={false}
              pl={"20px"}
              value={zipCode}
              handleChange={setZipCode}
            />
            {zipCodeError && (
              <Text
                color={"red.500"}
                position={"absolute"}
                bottom={"-20px"}
                left={"0px"}
                fontSize={"xs"}
                zIndex={3}
              >
                Please enter zip code
              </Text>
            )}
          </Box>
          <Button
            mt={{ base: "40px", lg: 0 }}
            w={{ base: "100%", lg: "auto" }}
            position={{ base: "relative", lg: "absolute" }}
            top={{ base: 0, lg: "50%" }}
            right={"0px"}
            transform={{ base: "translateY(0%)", lg: "translateY(-50%)" }}
            py={"2px"}
            bg={{ base: "blueC.100", lg: "transparent" }}
            _hover={{ bg: { base: "blueC.100", lg: "transparent" } }}
            zIndex={2}
            onClick={function () {
              +zipCode === +cookies.zip ? clearCookies() : getZipCode(zipCode)
              if(setShowLocation){
                setShowLocation(!showLocation)
            }
            }}
          >
            {+zipCode === +cookies.zip ? "Clear" : "Save"}
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default ShipTo;
