import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import "swiper/css/navigation";
import { memo } from "react";
import "swiper/css";
import ProductAttributes from "../ProductAttributes/ProductAttributes";
import OneItemSlider from "../Sliders/OneItemSlider";
import { getCurrentColor } from "../../constants";

const ItemInfoMobile = () => {
  const { colorMode } = useColorMode();

  return (
    <Flex display={{ base: "flex", lg: "none" }} direction="column">
      <Flex gap="14px" mb={2}>
        <Box w="50%">
          <OneItemSlider />
        </Box>
        <Box w="50%">
          <Text
            fontSize="22px"
            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
            fontWeight="600"
            fontFamily="mainFont"
          >
            Magtech Full Metal Jacket 115 Grain 9mm Luger
          </Text>
        </Box>
      </Flex>

      <Accordion allowMultiple>
        <AccordionItem
          borderRadius="5px"
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
          mb={2}
        >
          <AccordionButton
            borderRadius="5px"
            border="1px solid"
            borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
          >
            <Box as="span" flex="1" textAlign="left">
              <Text
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontWeight="500"
              >
                Product Attributes
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel
            borderRadius="5px"
            border="1px solid"
            borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
            p="19px"
            mt="2px"
          >
            <ProductAttributes />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default memo(ItemInfoMobile);
