import { Flex, Text, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";
import {useAtom} from "jotai/index";
import {atttibutesAtom} from "../../atom/groupListings";

const ProductAttributes = () => {
  const { colorMode } = useColorMode();
  const [productAttributes] = useAtom(atttibutesAtom)

  if(productAttributes){
    return (
        <>
            {productAttributes[0].caliber &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Caliber
                    </Text>
                    <Text color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}>
                        {productAttributes[0].caliber}
                    </Text>
                </Flex>
            }
            {productAttributes[0].bullet_weight_n &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Grain
                    </Text>
                    <Text color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}>
                        {productAttributes[0].bullet_weight_n}
                    </Text>
                </Flex>
            }
            {productAttributes[0].bullet_type &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Bullet Type
                    </Text>
                    <Text color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}>
                        {productAttributes[0].bullet_type}
                    </Text>
                </Flex>
            }
            {productAttributes[0].brand &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Manufacturer
                    </Text>
                    <Text color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}>
                        {productAttributes[0].brand}
                    </Text>
                </Flex>
            }
            {productAttributes[0].weapon_category &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Category
                    </Text>
                    <Text maxW={150} color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")} overflow='auto'>
                        {productAttributes[0].weapon_category}
                    </Text>
                </Flex>
            }
            {productAttributes[0].energy &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Muzzle Energy
                    </Text>
                    <Text maxW={150} color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")} overflow='auto'>
                        {productAttributes[0].energy}
                    </Text>
                </Flex>
            }
            {productAttributes[0].velocity &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Muzzle Velocity
                    </Text>
                    <Text maxW={150} color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")} overflow='auto'>
                        {productAttributes[0].velocity}
                    </Text>
                </Flex>
            }
            {productAttributes[0].material &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Casing
                    </Text>
                    <Text maxW={150} color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")} overflow='auto'>
                        {productAttributes[0].material}
                    </Text>
                </Flex>
            }
            {productAttributes[0].shell_length &&
                <Flex
                    justifyContent="space-between"
                    mb={4}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        Shell Length
                    </Text>
                    <Text maxW={150} color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")} overflow='auto'>
                        {productAttributes[0].shell_length}
                    </Text>
                </Flex>
            }
            {productAttributes[0].mpn &&
                <Flex
                    justifyContent="space-between"
                    mb={0}
                >
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        opacity="70%"
                        fontSize="15px"
                    >
                        MPN
                    </Text>
                    <Text maxW={160} color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")} overflow='auto'>
                        {productAttributes[0].mpn}
                    </Text>
                </Flex>
            }
        </>
    );
  }
};

export default ProductAttributes;
