import { Tag } from "@chakra-ui/react";
import { getCurrentColor } from "../constants";

export const getProductCharacteristic = (
  characteristics,
  colorMode,
  fontSize = "13px",
  fontWeight = "400",
) =>
  characteristics.map(
    (characteristic, index) =>
      characteristic?.length > 0 && (
        <Tag
          borderRadius="16px"
          fontSize={fontSize}
          key={characteristic + index}
          fontWeight={fontWeight}
          color={getCurrentColor(colorMode, "blackC.300", "grayC.1600")}
          bg={getCurrentColor(colorMode, "grayC.400", "grayC.1900")}
        >
          {characteristic}
        </Tag>
      ),
  );
