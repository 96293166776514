import { useSearchParams } from "react-router-dom";

const useSearchParamsHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const processAllParams = () => {
    const paramsEntries = Array.from(searchParams.entries());
    const paramsObject = paramsEntries.reduce((acc, [key, value]) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(value);
      return acc;
    }, {});

    Object.keys(paramsObject).forEach((key) => {
      paramsObject[key] = paramsObject[key].join(",");
    });

    return paramsObject;
  };

  const addOrUpdateParam = (key, value) => {
    const params = processAllParams();
    const existingValues = params[key] ? params[key].split(",") : [];
    if (!existingValues.includes(value)) {
      existingValues.push(value);

      if ((value + "").includes("-") && (key === "bw" || key === "rpb")) {
        setSearchParams({
          ...params,
          page: "1",
          sort_by: 'landed_cpr',
          [key]: value,
        });
      } else if (key !== "page") {
        setSearchParams({
          ...params,
          page: "1",
          sort_by: 'landed_cpr',
          [key]: existingValues.join(","),
        });
      } else {
        setSearchParams({
          ...params,
          [key]: existingValues.join(","),
        });
      }
    }
  };

  const deleteParam = (key, value) => {
    const params = processAllParams();
    const existingValues = params[key] ? params[key].split(",") : [];
    const filteredValues = existingValues.filter((v) => v + "" !== value + "");
    if (filteredValues.length === 0) {
      const { [key]: _, ...rest } = params;
      setSearchParams({ ...rest, page: "1" });
    } else {
      setSearchParams({
        ...params,
        page: "1",
        [key]: filteredValues.join(","),
      });
    }
  };

  const clearParams = () => {
    const keepOnlySelectedKeys = (obj) => {
      const selectedKeys = ["wc", "sort_by", "caliber", "page", "zip", "pgId"];
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (selectedKeys.includes(key)) {
          newObj[key] = obj[key];
        }
      });
      return { ...newObj, page: "1", sort_by: 'landed_cpr' };
    };

    setSearchParams(keepOnlySelectedKeys(processAllParams()));
  };

  return {
    params: processAllParams(),
    addOrUpdateParam,
    deleteParam,
    clearParams,
  };
};

export default useSearchParamsHandler;
