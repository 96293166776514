import { useForm } from "react-hook-form";
import useHttp from "../../hooks/useHttp";
import {
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  Textarea,
  useColorMode,
} from "@chakra-ui/react";
import {
  FormEmailIcon,
  FormUserIcon,
  PlainIcon,
} from "../../assets/icons/icons";
import { emailReg, getCurrentColor } from "../../constants";
import { useState } from "react";
import SuccessFormSend from "../SuccessFormSend/SuccessFormSend";
import ErrorFormSend from "../ErrorFormSend/ErrorFormSend";

const Form = () => {
  const { postMessage } = useHttp();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { colorMode } = useColorMode();
  const [messageStatus, setMessageStatus] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm();

  const onSubmit = (values) => {
    setMessageStatus(true);
    postMessage(values)
      .then((res) => {
        if (res.code === "ERR_NETWORK") {
          setError(true);
        } else if (res.status === 200) {
          setSuccess(true);
          clearErrors();
        }
      })
      .catch(() => setError(true))
      .finally(() => {
        setValue("name", "");
        setValue("email", "");
        setValue("message", "");
        setMessageStatus(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.value.length <= 1) {
      const newValue = e.target.value.trim();
      setValue(e.target.getAttribute("id"), newValue, { shouldValidate: true });
    } else {
      setValue(e.target.getAttribute("id"), e.target.value, {
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      {!error && !success && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mb={"25px"} isInvalid={errors.name}>
            <FormUserIcon
              position={"absolute"}
              left={"12px"}
              top={"50%"}
              transform={"translateY(-50%)"}
              zIndex={98}
              color={getCurrentColor(colorMode, "grayC.1900", "grayC.2100")}
            />
            <Input
              pl={"44px"}
              py={"11px"}
              pr={"12px"}
              fontFamily={"mainFont"}
              fontSize={"sm"}
              id="name"
              name="name"
              placeholder="Full Name"
              bg={getCurrentColor(colorMode, "whiteC.100", "grayC.2000")}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.500",
                "grayC.2200",
              )}
              {...register("name", {
                required: "This is required",
                minLength: { value: 2, message: "Minimum length should be 2" },
                onChange: handleChange,
              })}
            />
            <FormErrorMessage
              fontSize={"xs"}
              bottom={"-20px"}
              position={"absolute"}
            >
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl mb={"25px"} isInvalid={errors.email}>
            <FormEmailIcon
              position={"absolute"}
              left={"12px"}
              top={"50%"}
              transform={"translateY(-50%)"}
              zIndex={98}
              color={getCurrentColor(colorMode, "grayC.1900", "grayC.2100")}
            />
            <Input
              pl={"44px"}
              id="email"
              py={"11px"}
              pr={"12px"}
              fontFamily={"mainFont"}
              fontSize={"sm"}
              name="email"
              placeholder="Email Address"
              bg={getCurrentColor(colorMode, "whiteC.100", "grayC.2000")}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.500",
                "grayC.2200",
              )}
              {...register("email", {
                required: "This is required",
                pattern: {
                  value: emailReg,
                  message: "Invalid email address",
                },
                onChange: handleChange,
              })}
            />
            <FormErrorMessage
              fontSize={"xs"}
              bottom={"-20px"}
              position={"absolute"}
            >
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.message}>
            <Textarea
              id="message"
              fontFamily={"mainFont"}
              fontSize={"sm"}
              placeholder="Message"
              bg={getCurrentColor(colorMode, "whiteC.100", "grayC.2000")}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.500",
                "grayC.2200",
              )}
              {...register("message", {
                required: "This is required",
                minLength: {
                  value: 10,
                  message: "Minimum length should be 10",
                },
                onChange: handleChange,
              })}
              resize={"none"}
            />
            <FormErrorMessage
              fontSize={"xs"}
              bottom={"-20px"}
              position={"absolute"}
            >
              {errors.message && errors.message.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            display={"flex"}
            gap={2.5}
            mt={6}
            alignItems={"center"}
            bg={"blueC.100"}
            color={"whiteC.100"}
            isLoading={messageStatus}
            type="submit"
            w={"100%"}
            _hover={{
              bg: "blueC.200",
            }}
          >
            <PlainIcon />
            Send
          </Button>
        </form>
      )}

      {error}

      {!error && success && <SuccessFormSend setSuccess={setSuccess} />}
      {!success && error && <ErrorFormSend setError={setError} />}
    </>
  );
};
export default Form;
