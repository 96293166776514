import React from 'react';
import ProductList from "./ProductList";
import useUpdateListings from "../../hooks/useUpdateListings";

const ListingsList = () => {
    const { currentPage, allPages, productsInfo, productsLoadingStatus } = useUpdateListings();
    return (
        <ProductList currentPage={currentPage} allPages={allPages} productsInfo={productsInfo} />
    );
};

export default ListingsList;