import { memo } from "react";
import {
  DangerousIcon,
  MediumIcon,
  VettedIcon,
} from "../../assets/icons/icons";
import Filter from "./Filter";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { categoriesPageModeAtom, filterInfoAtom, productsLoadingStatusAtom as listingsLoadingStatusAtom } from "../../atom/listings";
import { filterInfoAtom as productsFiltersInfoAtom, productsLoadingStatusAtom  } from '../../atom/products';

const FiltersWrapper = () => {
  const location = useLocation();
  const [categoriesPageMode] = useAtom(categoriesPageModeAtom);
  const isProductsPageMode = categoriesPageMode === 'products';
  const [filterInfo] = useAtom(isProductsPageMode ? productsFiltersInfoAtom : filterInfoAtom);
  const [filtersLoadingStatus] = useAtom(isProductsPageMode ? productsLoadingStatusAtom : listingsLoadingStatusAtom);

  const shipping = [
    { name: "Verified", icon: <VettedIcon />, id: "1" },
    { name: "Caution", icon: <MediumIcon />, id: "2" },
    { name: "Unknown", icon: <DangerousIcon />, id: "3" },
  ];

  const createShipFlag = () =>
    filterInfo["ship_flag"]
      .map((item) => ({
        ...item,
        name: shipping[+item.name - 1].name,
        id: item.name,
        flag: item.name,
        icon: shipping[+item.name - 1].icon,
      }))
      .sort((a, b) => +b.id - +a.id);

  return (
    filterInfo !== null &&
    filtersLoadingStatus === "idle" && (
      <>
        {location.pathname.includes("Shotgun") ||
        location.pathname.includes("shotgun") ? (
          <>
            <Filter
              key={"ship_flag"}
              title={"Shipping"}
              data={createShipFlag()}
              checkbox={true}
              search={false}
              ship_flag={true}
              query={"sf"}
              name={"Shipping"}
            />
            <Filter
              key={"bullet_type"}
              title={"Shot Type"}
              data={filterInfo["bullet_type"]}
              checkbox={true}
              search={true}
              query={"bt"}
              name={"Bullet Type"}
            />
            <Filter
              key={"bullet_type_s"}
              title={"Shot Size"}
              data={filterInfo["bullet_type_s"]}
              checkbox={true}
              search={true}
              query={"bullet_type_s"}
              name={"Shot Type"}
            />
            <Filter
              key={"bullet_weight"}
              title={"Shot Weight"}
              data={filterInfo["bullet_weight"]}
              checkbox={true}
              slider={true}
              query={"bw"}
              name={"Bullet Weight"}
            />
            <Filter
              key={"shell_length"}
              title={"Shell Length"}
              data={filterInfo["shell_length"]}
              checkbox={true}
              search={false}
              query={"shell_length"}
              name={"Shot Type"}
            />
            <Filter
              key={"material"}
              title={"Shot Material"}
              data={filterInfo["material"]}
              checkbox={true}
              search={false}
              query={"mt"}
              name={"Casing"}
            />
            <Filter
              key={"rdsperbox"}
              title={"Number of Rounds"}
              data={filterInfo["rdsperbox"]}
              checkbox={true}
              slider={true}
              query={"rpb"}
              name={"Rounds"}
            />
            <Filter
              key={"brand"}
              title={"Brand"}
              data={filterInfo["brand"]}
              checkbox={true}
              search={true}
              query={"brnd"}
              name={"Brand"}
            />
              {!isProductsPageMode &&
                  <Filter
                      key={"vendor_name"}
                      title={"Vendor"}
                      data={filterInfo["vendor_name"]}
                      checkbox={true}
                      search={true}
                      query={"vid"}
                      name={"Vendor"}
                  />
              }
          </>
        ) : (
          <>
            <Filter
              key={"ship_flag"}
              title={"Shipping"}
              data={createShipFlag()}
              checkbox={true}
              search={false}
              ship_flag={true}
              query={"sf"}
              name={"Shipping"}
            />
            <Filter
              key={"bullet_weight"}
              title={"Bullet Weight"}
              data={filterInfo["bullet_weight"]}
              checkbox={true}
              slider={true}
              query={"bw"}
              name={"Bullet Weight"}
            />
            <Filter
              key={"rdsperbox"}
              title={"Number of Rounds"}
              data={filterInfo["rdsperbox"]}
              checkbox={true}
              slider={true}
              query={"rpb"}
              name={"Rounds"}
            />
            <Filter
              key={"bullet_type_s"}
              title={"Bullet Type"}
              data={filterInfo["bullet_type_s"]}
              checkbox={true}
              search={true}
              query={"bullet_type_s"}
              name={"Bullet Type"}
            />
            <Filter
              key={"material"}
              title={"Material"}
              data={filterInfo["material"]}
              checkbox={true}
              search={false}
              query={"mt"}
              name={"Material"}
            />
            <Filter
              key={"brand"}
              title={"Brand"}
              data={filterInfo["brand"]}
              checkbox={true}
              search={true}
              query={"brnd"}
              name={"Brand"}
            />
              {!isProductsPageMode &&
                  <Filter
                      key={"vendor_name"}
                      title={"Vendor"}
                      data={filterInfo["vendor_name"]}
                      checkbox={true}
                      search={true}
                      query={"vid"}
                      name={"Vendor"}
                  />
              }
          </>
        )}
      </>
    )
  );
};

export default memo(FiltersWrapper);
