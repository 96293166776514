import React from "react";
import {
  Flex,
  Heading,
  Link,
  List,
  ListItem,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { data } from "../../data";
import { getCurrentColor, headerLink } from "../../constants";
import { Link as RouterLink } from "react-router-dom";
import useCookie from "../../hooks/useCookie";

const MainPageDiscoverSection = () => {
  const { colorMode } = useColorMode();
  const { cookies } = useCookie();

  const renderInfo = React.useCallback(
    (data) => {
      const { category, subCategories, categoryLink } = data;

      return (
        <>
          <Heading
            key={category}
            as="h3"
            fontSize="2xl"
            color={getCurrentColor(colorMode, "blackC.200", "whiteC.200")}
            fontFamily="mainFont"
            fontWeight="600"
            mb={2}
            textAlign="start"
          >
            {category}
          </Heading>

          <Flex
            gap={{ base: 0, sm: 2 }}
            flexDirection={{ base: "column", sm: "row" }}
          >
            <List
              display="flex"
              gap="5px"
              flexWrap="wrap"
              justifyContent="start"
              mb={{ base: "5px", md: "16px" }}
              flexDirection="column"
            >
              {subCategories.map((caliber) => (
                <ListItem key={caliber.name}>
                  <Link
                    as={RouterLink}
                    to={headerLink(
                      // eslint-disable-next-line
                      category.toLowerCase().replace("/(?:s|-)?ammo/gi,", ""),
                      caliber.name,
                      cookies.zip,
                    )}
                    fontSize="16px"
                    fontFamily="mainFont"
                    fontWeight="500"
                    color={getCurrentColor(
                      colorMode,
                      "blackC.200",
                      "whiteC.200",
                    )}
                    borderBottom="1px solid"
                    borderColor={getCurrentColor(
                      colorMode,
                      "blackC.200",
                      "whiteC.200",
                    )}
                    _hover={{ textDecoration: "none", border: "none" }}
                    wordBreak="normal"
                  >
                    <ArrowForwardIcon mr={{ base: 1, md: 2 }} />
                    {caliber.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Flex>

          <Link
            as={RouterLink}
            // eslint-disable-next-line
            to={`/${categoryLink.toLowerCase()}`}
            color={"blueC.100"}
            fontWeight="500"
            fontFamily="mainFont"
            fontSize="md"
            mt="auto"
          >
            Shop {category} <ArrowForwardIcon ml={2} />
          </Link>
        </>
      );
    },
    // eslint-disable-next-line
    [colorMode],
  );

  return (
    <Flex
      maxW="1240px"
      w="100%"
      px={{ base: "22px", xl: "10px" }}
      pt={{ base: 8, lg: "60px" }}
      mx="auto"
      flexDirection="column"
      mb={{ base: "50px", lg: "60px" }}
    >
      <Heading
        as="h2"
        fontSize={{ base: "3xl", md: "4xl" }}
        color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
        fontFamily="mainFont"
        fontWeight="600"
        mb={2}
        textAlign="center"
      >
        Discover a vast selection of ammo
      </Heading>
      <Text
        color={getCurrentColor(colorMode, "grayC.100", "grayC.1600")}
        fontSize={{ base: "lg", md: "mb" }}
        fontFamily="mainFont"
        fontWeight="400"
        mb={{ base: 6, md: 8 }}
        textAlign="center"
      >
        Access a diverse range of ammunition types, calibers, and brands.
      </Text>

      <Flex
        flexWrap={{ base: "wrap", xl: "nowrap" }}
        gap={"20px"}
        mb={{ base: "20px", md: "30px" }}
      >
        {data.map((item, index) => (
          <Flex
            key={index}
            borderRadius="10px"
            w={{ base: "100%", md: "48%", xl: "25%" }}
            h="335px"
            px="20px"
            py="15px"
            border="1px solid"
            borderColor={getCurrentColor(colorMode, "grayC.500", "transparent")}
            bg={getCurrentColor(colorMode, "blueC.800", "grayC.1700")}
            position="relative"
          >
            <Flex
              flexDirection="column"
              maxW={index % 2 === 0 ? "408px" : "411px"}
              w="100%"
            >
              {renderInfo(item)}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default MainPageDiscoverSection;
