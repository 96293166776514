import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text, useColorMode,
} from "@chakra-ui/react";
import { useState } from "react";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import useConfirmPassword from "../hooks/useConfirmPassword";
import { Helmet } from "react-helmet";
import {getCurrentColor} from "../constants";

const ForgotPassword = () => {
  const {
    verificationCode,
    newPassword,
    error,
    tips,
    setVerificationCode,
    setNewPassword,
    handleConfirmPassword,
    step,
    handleForgotPassword,
    username,
    setUsername,
    codeError,
  } = useConfirmPassword();

  const [showPassword, setShowPassword] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      maxW={"360px"}
      w={"100%"}
      flexDirection={"column"}
      mx={"auto"}
      pt={{ base: "28px", md: "37px" }}
      pb={{ base: "129px", md: "100px" }}
      px={{ base: "20px", md: "10px" }}
    >
      <Helmet>
        <title>Forgot Password to Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      {step === 1 && (
        <Flex flexDirection={"column"}>
          <Heading
            as={"h2"}
            fontFamily={"mainFont"}
            fontSize={"2xl"}
            mb={{ base: "32px", md: "26px" }}
          >
            Forgot Password
          </Heading>
          <FormControl position={"relative"}>
            <Input
              mb={"30px"}
              placeholder="Enter your email"
              bg={"grayC.600"}
              border={"0.5px solid"}
              borderColor={codeError ? "red.500" : "grayC.1400"}
              focusBorderColor={codeError ? "red.500" : "grayC.1300"}
              type="text"
              value={username}
              color={getCurrentColor(colorMode, "grayC.1600", "blackC.100")}
              _placeholder={{ color: getCurrentColor(colorMode, "grayC.1600", "blackC.100")}}
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <Text
              position={"absolute"}
              bottom={"10px"}
              left={"0px"}
              color={"red.500"}
              fontSize={"12px"}
            >
              {error.length > 0 && error}
            </Text>
          </FormControl>
          <Button
            bg={"blueC.100"}
            color={"whiteC.100"}
            _hover={{ bg: "blueC.200" }}
            onClick={() => handleForgotPassword(username)}
          >
            Forgot Password
          </Button>
        </Flex>
      )}
      {step === 2 && (
        <Flex flexDirection={"column"}>
          <Heading
            as={"h2"}
            fontFamily={"mainFont"}
            fontSize={"2xl"}
            mb={{ base: "32px", md: "26px" }}
          >
            Reset Password
          </Heading>
          <Text mb={"20px"}>Verification code sended to your email</Text>
          <FormControl position={"relative"}>
            <Input
              mb={"20px"}
              placeholder="Verification Code"
              bg={"grayC.600"}
              border={"0.5px solid"}
              borderColor={codeError ? "red.500" : "grayC.1400"}
              focusBorderColor={codeError ? "red.500" : "grayC.1300"}
              color={getCurrentColor(colorMode, "grayC.1600", "blackC.100")}
              _placeholder={{ color: getCurrentColor(colorMode, "grayC.1600", "blackC.100")}}
              type="text"
              name="verification_code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <Text
              position={"absolute"}
              color={"red.500"}
              fontStyle={"12px"}
              bottom={0}
              left={0}
              fontSize={"12px"}
            >
              {codeError && "Please enter a valid code"}
            </Text>
          </FormControl>
          <FormControl position={"relative"}>
            <InputGroup size="md" mb={"29px"}>
              <Input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                border={"0.5px solid"}
                borderColor={"grayC.1400"}
                bg={"grayC.600"}
                focusBorderColor={"grayC.1300"}
                color={getCurrentColor(colorMode, "grayC.1600", "blackC.100")}
                _placeholder={{ color: getCurrentColor(colorMode, "grayC.1600", "blackC.100")}}
                id="password"
                name="password"
              />
              <InputRightElement width="4.5rem">
                <Button
                  bg={"transparent"}
                  border={"none"}
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                  _hover={{ bg: "transparent" }}
                >
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>

            {error.length > 0 && (
              <Text
                position={"absolute"}
                bottom={"-5px"}
                left={"0"}
                fontSize={"12px"}
                color={"red.500"}
                mb={"10px"}
              >
                {error}
              </Text>
            )}
            {tips.length > 0 && (
              <Text
                position={"absolute"}
                bottom={"0px"}
                left={"0"}
                fontSize={"12px"}
                color={"green.500"}
              >
                {tips}
              </Text>
            )}
          </FormControl>
          <Button
            bg={"blueC.100"}
            color={"whiteC.100"}
            _hover={{ bg: "blueC.200" }}
            onClick={() => handleConfirmPassword(username)}
          >
            Confirm Password
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default ForgotPassword;
