import { atom } from "jotai";
import useHttp from "../hooks/useHttp";

export const productsInfoAtom = atom(null);
export const categoriesPageModeAtom = atom(localStorage.getItem('categoriesPageMode') || 'products')
export const productsLoadingStatusAtom = atom("idle");
export const currentPageAtom = atom(1);
export const allPagesAtom = atom(1);
export const productPerPageAtom = atom(0);
export const allProductsAtom = atom(0);
export const filtersLoadingStatusAtom = atom("loading");
export const filterInfoAtom = atom(null);
export const sortByAtom = atom("landed_cpr");
export const roundsAtom = atom("rounds");
export const retailerAtom = atom("retailer");
export const shippingAtom = atom("shipping");
export const selectedFiltersAtom = atom({
  bw: [],
  vid: [],
  brnd: [],
  sf: [],
  bullet_type_s: [],
  bt: [],
  shell_length: [],
  mt: [],
  rpb: [],
});

export const fetchProductsAtom = atom(null, async (get, set, query) => {
  set(productsLoadingStatusAtom, "loading");
  set(filtersLoadingStatusAtom, "loading");
  const { getListings } = useHttp();
  getListings(query)
    .then((response) => {
      set(productsInfoAtom, response.response.data);
      set(
        allPagesAtom,
        Math.ceil(+response.response.total / +response.response.per_page),
      );
      set(productPerPageAtom, response.response.per_page);
      set(allProductsAtom, response.response.total);
      set(filterInfoAtom, response.facets);
    })
    .finally(() => {
      set(productsLoadingStatusAtom, "idle");
      set(filtersLoadingStatusAtom, "idle");
    });
});
