import ammo from "./assets/image/categories/9mm.webp";

export const data = [
  {
    category: "Handgun Ammo",
    index: "disclosure-:r0:",
    subCategories: [
      {
        name: "5.7x28mm FN",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "9mm Luger / Parabellum",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "10mm Auto",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".357 Magnum",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".38 Special",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".380 ACP",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".40 S&W",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".45 ACP/Auto",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
    ],
    categoryLink: "handgun-ammo",
  },
  {
    category: "Rifle Ammo",
    index: "disclosure-:ro:",
    subCategories: [
      {
        name: ".223 Remington",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".243 Winchester",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".30-06 Springfield",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".300 AAC Blackout",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".308 Win / 7.62x51mm",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "5.56x45mm NATO",
        link: "/handgun/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "6.5mm Creedmoor",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "7.62x39mm",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
    ],
    categoryLink: "rifle-ammo",
  },
  {
    category: "Shotgun Ammo",
    index: "disclosure-:r1e:",
    subCategories: [
      {
        name: ".410 Bore",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "10 Gauge",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "12 Gauge",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "16 Gauge",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "20 Gauge",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: "28 Gauge",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
    ],
    categoryLink: "shotgun-ammo",
  },
  {
    category: "Rimfire Ammo",
    index: "disclosure-:r22:",
    subCategories: [
      {
        name: ".17 HMR",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".17 WSM",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".22 Long Rifle",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".22 Magnum / WMR",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      {
        name: ".22 Short",
        link: "/handgun-ammo/9mm-ammo",
        src: ammo,
        count: "5,392",
      },
      // {
      //   name: ".22 WRF",
      //   link: "/handgun-ammo/9mm-ammo",
      //   src: ammo,
      //   count: "5,392",
      // },
    ],
    categoryLink: "rimfire-ammo",
  },
];

export const calibers = {
  popular: [
    {
      name: "9mm",
      counted: "5,392",
      link: "/handgun-ammo/9mm-ammo",
    },
    {
      name: "5.56mm",
      counted: "5,392",
      link: "/handgun-ammo/5.56-ammo",
    },
    {
      name: "7.62mm",
      counted: "5,392",
      link: "/handgun-ammo/7.62-ammo",
    },
  ],
  allCalibers: [
    {
      name: "9mm",
      counted: "5,392",
      link: "/handgun-ammo/9mm-ammo",
    },
    {
      name: "5.56mm",
      counted: "5,392",
      link: "/handgun-ammo/5.56-ammo",
    },
    {
      name: "7.62mm",
      counted: "5,392",
      link: "/handgun-ammo/7.62-ammo",
    },
    {
      name: "9mm",
      counted: "5,392",
      link: "/handgun-ammo/9mm-ammo",
    },
    {
      name: "5.56mm",
      counted: "5,392",
      link: "/handgun-ammo/5.56-ammo",
    },
    {
      name: "7.62mm",
      counted: "5,392",
      link: "/handgun-ammo/7.62-ammo",
    },
    {
      name: "9mm",
      counted: "5,392",
      link: "/handgun-ammo/9mm-ammo",
    },
    {
      name: "5.56mm",
      counted: "5,392",
      link: "/handgun-ammo/5.56-ammo",
    },
    {
      name: "7.62mm",
      counted: "5,392",
      link: "/handgun-ammo/7.62-ammo",
    },
    {
      name: "9mm",
      counted: "5,392",
      link: "/handgun-ammo/9mm-ammo",
    },
    {
      name: "5.56mm",
      counted: "5,392",
      link: "/handgun-ammo/5.56-ammo",
    },
    {
      name: "7.62mm",
      counted: "5,392",
      link: "/handgun-ammo/7.62-ammo",
    },
  ],
};

export const faq = [
  {
    title: "Does BulletScout sell ammunition?",
    answer:
      "No, BulletScout does not sell ammunition. We are a search engine that helps you find in-stock ammunition from various online retailers. Our goal is to provide you with the latest information and prices to help you make informed purchasing decisions.",
  },
  {
    title:
      "What is the difference between the “Item Cost” and “Total Cost” listed?",
    answer:
      "The “Item Cost” refers to the base price of the ammunition itself before any additional charges. The “Total Cost,” on the other hand, includes the item cost and shipping fees. This total cost gives you a complete view of what you'll actually pay when purchasing, helping you to compare the full expense across different retailers.",
  },
  {
    title: "What does CPR (Cents per Round) mean?",
    answer:
      "CPR stands for Cents per Round, a metric that shows the total cost per individual round of ammunition. By considering all these factors, CPR provides a comprehensive view of the actual cost, helping you make informed decisions when comparing prices from different retailers.",
  },
  {
    title: "Is shipping and tax included in the listed price?",
    answer:
      "The item price on BulletScout shows the base cost of the ammunition. Shipping is not included in this item price but are factored into the “Total Cost” field. This total cost calculation combines the item price, taxes, and shipping fees, giving you a complete view of the actual expense when purchasing.",
  },
  {
    title: "How are retailer listings ranked in the search results?",
    answer:
      "Retailer listings are ranked primarily based on the price per round, with the lowest-priced ammunition appearing at the top. This helps you quickly find the most cost-effective options. Sponsored products may also be featured prominently, but overall, our goal is to ensure you see the best deals first.",
  },
  {
    title: "How Frequently does BulletScout update their ammunition data?",
    answer:
      "BulletScout updates its ammunition data every few minutes, 24/7. This ensures that you have access to the most current information on available ammunition and pricing. However, since stock levels can change rapidly, we recommend checking the retailer's site for the latest availability.",
  },
  {
    title: "How do you verify shipping estimations from ammunition retailers?",
    answer:
      "We verify ammunition retailers using a multi-step process. This includes checking their credibility, reviewing customer feedback, and assessing their shipping and pricing practices. Retailers that meet our standards are marked with a green checkmark, while those we cannot verify are marked with a red checkmark. Retailers with potential price fluctuations are indicated with a yellow check mark. This system helps ensure that you receive accurate and reliable information when choosing a retailer.",
  },
  {
    title:
      "The listing I selected says it’s out of stock on the vendor’s website, what happened?",
    answer:
      "While BulletScout updates its data frequently, stock levels can change rapidly. If an item is listed as in stock on our site but appears out of stock on the vendor's website, it may be due to a delay in data synchronization or sudden changes in inventory. We recommend checking the vendor’s website directly for the most current stock status and availability. If you continue to encounter issues, please let us know so we can address any discrepancies.",
  },
  {
    title: "Why isn't my preferred caliber or retailer listed on BulletScout?",
    answer:
      "We strive to include a wide range of calibers and reputable retailers in our search engine. If your preferred caliber or retailer isn’t listed, it could be due to several reasons, such as the retailer not being part of our current database or the caliber not being available from the retailers we index. If you have specific suggestions or requests, please contact us, and we’ll consider adding them to our listings.",
  },
  {
    title: "How can I advertise my product or site on BulletScout?",
    answer:
      "To advertise your product or site on BulletScout, please visit our advertising information page for details on rates and available options. You can also contact us directly to discuss your advertising needs and how we can help promote your offerings effectively.",
  },
  {
    title: "Which web browser works best with BulletScout?",
    answer:
      "For the optimal experience on BulletScout, we recommend using Firefox or Google Chrome. However, our site is compatible with all major web browsers, so you can use whichever one you prefer.",
  },
  {
    title:
      "Are you affiliated with any of the ammunition retailers listed on BulletScout?",
    answer:
      "BulletScout is not affiliated with any of the ammunition retailers listed on our site. We provide a platform for comparing prices and availability from various retailers. While we may receive affiliate commissions or listing fees from some retailers, our goal is to offer unbiased and accurate information to help you find the best deals.",
  },
  {
    title: "How can I report a complaint about a retailer listed on your site?",
    answer:
      "To report a complaint about a retailer listed on BulletScout, please contact us directly with the details of your issue. We take complaints seriously and will investigate the matter. If we receive multiple complaints about a retailer, we will review their listing and may take appropriate action to address the concerns. Your feedback helps us maintain the quality and reliability of our listings.",
  },
];

export const productAttributes = [
  {
    attribute: "Caliber",
    info: "9MM",
  },
  {
    attribute: "Grain",
    info: "115",
  },
  {
    attribute: "Bullet Type",
    info: "Full Metal Jacket",
  },
  {
    attribute: "Casing",
    info: "Steel",
  },
  {
    attribute: "Condition",
    info: "New",
  },
  {
    attribute: "Manufacturer",
    info: "Magtech",
  },
  {
    attribute: "UPC(s)",
    info: "754908189410, 754908189403",
  },
];

export const priceStatistics = [
  { statistic: "Current Price", info: "$0.47" },
  { statistic: "24h Price Change", info: "-$7.52 (-12.66%)" },
  { statistic: "24h Trading Volume", info: "$2,127.90" },
  { statistic: "Market Cap", info: "$12,559.80" },
  { statistic: "Volume / Market Cap", info: "0.1694" },
  { period: "7 Days", range: [0.39, 0.51] },
  { period: "30 Days", range: [0.42, 0.48] },
  { period: "All Time", range: [0.36, 0.55] },
];

export const similarProducts = [
  {
    logo: "https://cdn.bulletscout.com/image/main-page/brands/11.webp",
    description:
      "Barnes VOR-TX XPB Jacketed Hollow Point 115 Grain 9mm Luger 20 Rds",
    characteristics: [
      "9mm",
      "115 Grain",
      "Jacketed Hollow Point",
      "Brass",
      "New",
    ],
    countOfOffers: 66,
    averagePrice: 0.51,
  },
  {
    logo: "https://cdn.bulletscout.com/image/main-page/brands/11.webp",
    description: "Blazer Brass Full Metal Jacket 115 Grain 9mm Luger 100 Rds",
    characteristics: ["9mm", "115 Grain", "Full Metal Jacket", "Brass", "New"],
    countOfOffers: 75,
    averagePrice: 0.33,
  },
  {
    logo: "https://cdn.bulletscout.com/image/main-page/brands/11.webp",
    description:
      "Federal Champion Training Full Metal Jacket 115 Grain 9mm Luger 100 Rds",
    characteristics: ["9mm", "115 Grain", "Full Metal Jacket", "Brass", "New"],
    countOfOffers: 43,
    averagePrice: 0.39,
  },
  {
    logo: "https://cdn.bulletscout.com/image/main-page/brands/11.webp",
    description:
      "Barnes VOR-TX XPB Jacketed Hollow Point 115 Grain 9mm Luger 20 Rds",
    characteristics: [
      "9mm",
      "115 Grain",
      "Jacketed Hollow Point",
      "Brass",
      "New",
    ],
    countOfOffers: 66,
    averagePrice: 0.51,
  },
  {
    logo: "https://cdn.bulletscout.com/image/main-page/brands/11.webp",
    description: "Blazer Brass Full Metal Jacket 115 Grain 9mm Luger 100 Rds",
    characteristics: ["9mm", "115 Grain", "Full Metal Jacket", "Brass", "New"],
    countOfOffers: 75,
    averagePrice: 0.33,
  },
  {
    logo: "https://cdn.bulletscout.com/image/main-page/brands/11.webp",
    description:
      "Federal Champion Training Full Metal Jacket 115 Grain 9mm Luger 100 Rds",
    characteristics: ["9mm", "115 Grain", "Full Metal Jacket", "Brass", "New"],
    countOfOffers: 43,
    averagePrice: 0.39,
  },
];
