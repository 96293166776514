import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { getCurrentColor } from "../constants";
import { faq } from "../data";
import {useEffect} from "react";

const FaqPage = () => {
  const { colorMode } = useColorMode();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      pt={{ base: "34px", md: "66px" }}
      maxW={"800px"}
      w={"100%"}
      mx={"auto"}
      pb={{ base: "55px", md: "100px" }}
      px={{ base: "22px", lg: "10px" }}
    >
      <Helmet>
        <title>Faq page</title>
        <meta name="description" content="FAQ" />
      </Helmet>
      <Heading
        fontFamily={"mainFont"}
        color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
        mb={{ base: "14px", md: "30px" }}
        maxW={"600px"}
        w={"100%"}
        fontSize={{ base: "30px", md: "40px" }}
      >
        FAQ
      </Heading>

      <Accordion allowMultiple defaultIndex={[0]}>
        {faq.map((item) => {
          return (
            <AccordionItem
              _hover={{ outline: "none", bg: "none" }}
              mb={2}
              shadow={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
            >
              <AccordionButton>
                <Box as="span" flex="1" fontWeight={700} textAlign="left">
                  {item.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text>{item.answer}</Text>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
};

export default FaqPage;
