export const shippingName = (flag) => {
    switch(flag) {
        case 1: {
            return "Verified";
        }
        case 2: {
            return "Caution";
        }
        case 3: {
            return "Unknown";
        }
    }
}
