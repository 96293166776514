import { getCurrentColor } from "../../constants";
import SelectSort from "../Select/SelectSort";

import BreadcrumbCustom from "../BreadcrumbCustom/BreadcrumbCustom";

import {
  Box,
  Flex,
  Heading,
  Skeleton, Switch,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import {
  allProductsAtom,
  productsLoadingStatusAtom,
} from "../../atom/products";

import {
  allProductsAtom as allListingsAtom, categoriesPageModeAtom,
  productsLoadingStatusAtom as listingsLoadingStatusAtom,
} from "../../atom/listings";
import ItemInfoMobile from "../ItemInfo/ItemInfoMobile";
import {atttibutesAtom, allProductsAtom as groupListingsAllProducts} from "../../atom/groupListings";

const InfoOnProductsPage = ({ slider }) => {
  const { colorMode } = useColorMode();
  const [categoriesPageMode, setCategoriesPageMode] = useAtom(categoriesPageModeAtom);
  const [productAttributes] = useAtom(atttibutesAtom)

  const handleCategoriesPageModeToggle = () => {
    const newMode = categoriesPageMode === 'listings' ? 'products' : 'listings';
    setCategoriesPageMode(newMode);
    localStorage.setItem("categoriesPageMode", newMode);
  }

  const isListingsMode = categoriesPageMode === "listings";

  const location = useLocation();
  const [productsLoadingStatus] = useAtom(isListingsMode ? listingsLoadingStatusAtom : productsLoadingStatusAtom);
  const [allProducts] = useAtom(slider ? groupListingsAllProducts : isListingsMode ? allListingsAtom : allProductsAtom);

  return (
    <>
      <BreadcrumbCustom />
      {slider && <ItemInfoMobile />}
      <Flex
        maxW={{ base: "100%", xl: slider ? "100%" : "995px" }}
        flexDirection={'column'}
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        ml={"auto"}
        mb={{ base: "23px", md: "33px" }}
      >
        <Flex width={'100%'} justifyContent={'space-between'} alignItems={"center"}>
          <Flex flexDirection={"column"} gap={"5px"}>
            {!slider && (
                <Heading
                    fontSize={"22px"}
                    color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
                    fontWeight={"600"}
                    fontFamily={"mainFont"}
                >
                  {decodeURIComponent(location.pathname)
                      .split("/")
                      .slice(2)
                      .join("/")}
                </Heading>
            )}
            {productsLoadingStatus === "loading" ? (
                <Skeleton w={"100px"} h={"20px"} />
            ) : (
                <>
                  {slider ? (
                      <Flex
                          gap={'5px'}
                      >
                        <Box
                            w="100%"
                            maxW={{ base: "290px", xl: "420px" }}
                            display={{ base: "none", lg: "block" }}
                        >
                          <Text
                              fontSize="22px"
                              color={getCurrentColor(
                                  colorMode,
                                  "blackC.100",
                                  "whiteC.100",
                              )}
                              fontWeight="600"
                              fontFamily="mainFont"
                          >
                            {productAttributes && productAttributes[0].title}
                          </Text>
                        </Box>
                        <Text
                            fontSize={{ base: "md", md: "lg" }}
                            color={getCurrentColor(
                                colorMode,
                                "blackC.200",
                                "whiteC.100",
                            )}
                            fontWeight={"500"}
                            fontFamily={"mainFont"}
                        >
                          {allProducts} Active offers
                        </Text>
                      </Flex>
                  ) : (
                      <Text
                          fontSize={"md"}
                          color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
                          fontWeight={"400"}
                          fontFamily={"mainFont"}
                      >
                        {/* {currentPage} page for  */}
                        {allProducts} results.
                      </Text>
                  )}
                </>
            )}
          </Flex>
          <SelectSort slider={slider} />
        </Flex>
        {!slider &&
            <Flex justifySelf={'flex-start'} width={'100%'} display={{ base: "flex", xl: "none" }} gap={2} mr={2} mt={2}>
              <Text
                  cursor={"pointer"}
                  onClick={handleCategoriesPageModeToggle}
                  ml={2}
                  display={"flex"}
                  alignItems={"center"}
                  fontWeight={500}
                  fontSize={"15px"}
                  color={getCurrentColor(colorMode, categoriesPageMode === "products" ? "grayC.2300" : "blackC.100", categoriesPageMode === "products" ? "grayC.2300" :"whiteC.1600")}
              >
                Listings
              </Text>
              <Switch
                  colorScheme="blue"
                  isChecked={categoriesPageMode === "products"}
                  onChange={handleCategoriesPageModeToggle}
              />
              <Text
                  cursor={"pointer"}
                  onClick={handleCategoriesPageModeToggle}
                  display={"flex"}
                  alignItems={"center"}
                  fontWeight={500}
                  fontSize={"15px"}
                  color={getCurrentColor(colorMode, categoriesPageMode !== "products" ? "grayC.2300" : "blackC.100", categoriesPageMode !== "products" ? "grayC.2300" : "whiteC.100")}
              >
                Products
              </Text>
            </Flex>
        }
      </Flex>
    </>
  );
};

export default InfoOnProductsPage;
