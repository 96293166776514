import { Box, Flex, Image, Link, Text, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";
import { getProductCharacteristic } from "../../utils/getProductCharacteristic";

const SimilarProduct = ({ product }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      pt={{ base: "14px", lg: "18px" }}
      px={{ base: 5, lg: 7 }}
      pb={{ base: "21px", lg: "17px" }}
      border="1px solid"
      borderRadius="5px"
      boxShadow="0px 4px 10px 0px #1E1E1E0D"
      borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
      bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
      mb={3}
    >
      <Image
        src={product.logo}
        alt=""
        mx={window.innerWidth < 992 ? "auto" : "inherit"}
        mb={3}
      />
      <Text
        fontWeight="600"
        fontSize="lg"
        mb="11px"
        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
      >
        {product.description}
      </Text>
      <Flex gap="5px" flexWrap="wrap" mb={5}>
        {getProductCharacteristic(
          product.characteristics,
          colorMode,
          "10px",
          "500",
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Box>
          <Text color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}>
            {product.countOfOffers} offers from:
          </Text>
          <Text
            fontWeight="700"
            fontSize="xl"
            color={getCurrentColor(colorMode, "blueC.400", "whiteC.100")}
          >
            ${product.averagePrice} CPR
          </Text>
        </Box>

        <Link
          href="#"
          px="14.5px"
          py="2.5px"
          bg="blueC.100"
          target="_blank"
          _hover={{ bg: "blueC.200" }}
          color="whiteC.100"
          borderRadius="6px"
          fontWeight="500"
          w="185px"
          fontSize="15px"
          textAlign="center"
        >
          View All Offers & Product Info
        </Link>
      </Flex>
    </Box>
  );
};

export default SimilarProduct;
