import {useCallback, useEffect, useMemo, useRef} from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import useSearchParamsHandler from "./useSearchParamsHandler";
import {
    allPagesAtom,
    allProductsAtom,
    currentPageAtom,
    fetchProductsAtom,
    productPerPageAtom,
    productsInfoAtom,
    productsLoadingStatusAtom,
} from "../atom/groupListings";
import { useAtom } from "jotai";
import {periodChangeFlagAtom} from "../atom/priceHistory";

const useUpdateGroupListings = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { params } = useSearchParamsHandler();
    const [, fetchProducts] = useAtom(fetchProductsAtom);
    const [productsLoadingStatus, setProductsLoadingStatus] = useAtom(
        productsLoadingStatusAtom,
    );
    const [productsInfo] = useAtom(productsInfoAtom);
    const [currentPage] = useAtom(currentPageAtom);
    const [allPages] = useAtom(allPagesAtom);
    const [productPerPage] = useAtom(productPerPageAtom);
    const [allProducts] = useAtom(allProductsAtom);
    const [periodChangeFlag , setPeriodChangeFlag] = useAtom(periodChangeFlagAtom);

    const removeFromSearch = useCallback(() => {
        const params = new URLSearchParams(location.search);
        params.delete("period");
        return params.toString();
    }, [location.search]);

    const settingProducts = useCallback(async (location) => {
        try {
            setProductsLoadingStatus("loading");
            await fetchProducts(location);
        } catch (e) {
            console.error(e);
        } finally {
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!location.search.includes(`page=${currentPage}`)) {
            setSearchParams({ ...params, page: currentPage });
        }
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        if(!periodChangeFlag){
            window.scrollTo(0, 0);

            const updatedSearch = removeFromSearch();
            settingProducts(updatedSearch.startsWith("?") ? updatedSearch.slice(1) : updatedSearch);
        }

        setPeriodChangeFlag(false);

        return;
        // eslint-disable-next-line
    }, [searchParams]);

    const data = useMemo(
        () => ({
            currentPage,
            allPages,
            productsInfo,
            productPerPage,
            allProducts,
            productsLoadingStatus,
        }),
        [
            currentPage,
            allPages,
            productsInfo,
            productPerPage,
            allProducts,
            productsLoadingStatus,
        ],
    );

    return data;
};

export default useUpdateGroupListings;
