import React, {memo, useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import {Box, Flex, Select, useColorMode, Text} from "@chakra-ui/react";
import {getCurrentColor} from "../../constants";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {useAtom} from "jotai";
import {periodAtom, periodChangeFlagAtom} from "../../atom/priceHistory";
import {useSearchParams} from "react-router-dom";
import useSearchParamsHandler from "../../hooks/useSearchParamsHandler";
import useUpdatePriceHistory from "../../hooks/useUpdatePriceHistory";

const PriceHistory = memo(function PriceHistory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { params, clearParams } = useSearchParamsHandler();
  const { colorMode } = useColorMode();
  const [period, setPeriod] = useAtom(periodAtom);
  const { productsInfo: priceHistoryInfo, productsLoadingStatus: priceHistoryLoadingStatus } = useUpdatePriceHistory();
  const [, setPeriodChangeFlag] = useAtom(periodChangeFlagAtom);

  const priceHistoryDates = [];

  const filteredPriceHistory = [];

  priceHistoryInfo?.map(item => {
    if(!priceHistoryDates.includes(item?.date)){
      priceHistoryDates.push(item?.date);
      filteredPriceHistory.push(item)
    }
  });

  const dates = filteredPriceHistory?.map(item => {
    const date = new Date(item.date);
    const options = { day: '2-digit', month: 'short' };
    return new Intl.DateTimeFormat('en-Gb', options).format(date);
  });

  const prices = filteredPriceHistory?.map(item => {
    return Number(item.cpr);
  })

  const offers = filteredPriceHistory?.map(item => {
    return item.number_of_offers;
  })

  useEffect(() => {
    if (searchParams.get("period") !== period) {
      setPeriodChangeFlag(true);
      setSearchParams({ ...params, period: period }, { replace: true });
    }
  }, [period]);

  useEffect(() => {
    return () => {
      setPeriod('3M')
    }
  }, []);

  const options = {
    chart: {
      zooming: {
        type: 'xy',
      },
      backgroundColor: getCurrentColor(colorMode, 'white', '#212936'),
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        categories: dates ? dates : [],
        crosshair: true,
        tickLength: 0,
        labels: {
          style: {
            color: getCurrentColor(colorMode, '#212936', 'white'),
          },
        },
      },
    ],
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        labels: {
          format: '{value}',
          style: {
            color: getCurrentColor(colorMode, '#212936', 'white'),
          },
        },
        height: '70%',
        top: '0%',
        offset: 0,
        tickLength: 0,
        title: {
          text: '',
        },
        gridLineWidth: 0,
        opposite: true,
      },
      {
        labels: {
          enabled: false,
        },
        top: '85%',
        height: '15%',
        offset: 0,
        tickLength: 0,
        title: {
          text: '',
        },
        style: {
          color: getCurrentColor(colorMode, '#5FA5F9', 'gray'),
        },
        gridLineWidth: 0,
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        const index = this.points[0]?.point.index;
        const vendorName = filteredPriceHistory[index]?.vendor_name ?? 'N/A';
        const truncatedVendor =
            vendorName.length > 15 ? `${vendorName.substring(0, 15)}...` : vendorName;

        return `
      <div style="line-height: 1.5;">
        <div><b>Date:</b> ${dates[index]}</div>
        <div><b>CPR:</b> ${this.points[1]?.y}</div>
        <div><b>Offers:</b> ${this.points[0]?.y}</div>
        <div><b>Vendor:</b> ${truncatedVendor}</div>
      </div>
    `;
      },
    },

    legend: {
      align: 'left',
      verticalAlign: 'top',
    },
    series: [
      {
        showInLegend: false,
        type: 'column',
        lineWidth: 0,
        yAxis: 1,
        data: offers ? offers : [],
        color: '#5FA5F9',
        name: 'Offers',
      },
      {
        color: '#5FA5F9',
        showInLegend: false,
        type: 'spline',
        data: prices ? prices : [],
        name: 'CPR',
      },
    ],
  };


  return (
      <Box
          mt="47px"
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
          border={"1px solid"}
          borderColor={getCurrentColor(colorMode, "grayC.3400", "grayC.2400")}
          borderRadius="5px"
          pt="19px"
          pr={{ base: "9px", md: "15px" }}
      >
        <Flex
            justifyContent="space-between"
            alignItems="center"
            mb="10px"
            pr={{ base: "9px", md: "15px" }}
            pl={{ base: "9px", md: "25px" }}
        >
          <Text
              fontSize="xl"
              fontWeight="bold"
              color={getCurrentColor(colorMode, "#212936", "white")}
          >
            Price History:
          </Text>
          <Box
              position={"relative"}
              border={"1px solid"}
              borderColor={"blackC.600"}
              borderRadius={"6px"}
              bg={"transparent"}
              cursor={"pointer"}
              display={"block"}
              width={"150px"}
              margin={"0"}
          >
            <ChevronDownIcon
                color={"grayC.1600"}
                position={"absolute"}
                top={"50%"}
                transform={"translateY(-50%)"}
                right={"14px"}
                w={"20px"}
                h={"20px"}
                cursor={"pointer"}
            />
            <Select
                cursor={"pointer"}
                _focus={{ outline: "none" }}
                p={0}
                pr={"20px"}
                maxW={"190px"}
                position={"relative"}
                top={0}
                bg={"transparent"}
                fontSize={{ base: "10", "1xs": "sm" }}
                variant={"withoutIcon"}
                icon={"none"}
                value={period}
                onChange={({ target }) => setPeriod(target.value)}
            >
              <option value={'3M'}>3 Months</option>
              <option value={'1Y'}>Year</option>
            </Select>
          </Box>
        </Flex>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          />
      </Box>
  );
})

export default PriceHistory
