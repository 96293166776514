import { Box, Button, Flex } from "@chakra-ui/react";
import {
  FilterByIcon,
  LocationIcon,
  NavigateIcon,
} from "../../../assets/icons/icons";
import { memo, useEffect, useState } from "react";
import QuickNavigateMobile from "./QuickNavigateMobile";
import MobileMenuWrapper from "../../MobileMenuWrapper/MobileMenuWrapper";

import SelectedFilters from "../SelectedFilters";
import FiltersWrapper from "../FiltersWrapper";
import FilterSkeletonMobile from "../../../skeletons/Filters/FilterSkeletonMobile";

import {
  filterInfoAtom,
  filtersLoadingStatusAtom,
  categoriesPageModeAtom
} from "../../../atom/listings";

import { filterInfoAtom as productsFilterInfoAtom, filtersLoadingStatusAtom as productsFiltersLoadingStatusAtom } from '../../../atom/products';
import { useAtom } from "jotai";
import ShipTo from "../ShipTo";

const FiltersMobile = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [showNavigate, setShowNavigate] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showShipTo] = useState("Add zip code");

  const [categoriesPageMode] = useAtom(categoriesPageModeAtom);

  const isListingsMode = categoriesPageMode !== 'products';

  const [filterInfo] = useAtom(isListingsMode ? filterInfoAtom : productsFilterInfoAtom);
  const [filtersLoadingStatus] = useAtom(isListingsMode ? filtersLoadingStatusAtom : productsFiltersLoadingStatusAtom);

  useEffect(() => {
    if (showFilters || showNavigate || showLocation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showFilters, showNavigate, showLocation]);

  useEffect(() => {
    if (showFilters) {
      setShowNavigate(false);
      setShowLocation(false);
    }
  }, [showFilters]);

  useEffect(() => {
    if (showNavigate) {
      setShowFilters(false);
      setShowLocation(false);
    }
  }, [showNavigate]);

  useEffect(() => {
    if (showLocation) {
      setShowFilters(false);
      setShowNavigate(false);
    }
  }, [showLocation]);

  return (
    <Flex
      justifyContent={"space-between"}
      position={"fixed"}
      bottom={0}
      left={0}
      display={{ base: "flex", lg: "none" }}
      w={"100%"}
      h={"57px"}
      bg={"blackC.800"}
      zIndex={200}
    >
      <Flex pt={"9px"} pb={"5px"} bg={"blackC.800"} w={"100%"} zIndex={200}>
        <Button
          variant="unstyled"
          w={"100%"}
          borderRadius={0}
          color={"whiteC.100"}
          fontSize={"15px"}
          fontWeight={"600"}
          display={"flex"}
          gap={"6px"}
          onClick={() => setShowNavigate(!showNavigate)}
          position={"relative"}
          zIndex={100}
          bg={"blackC.800"}
        >
          <NavigateIcon w={"5px"} h={"14px"} />
          Navigate
        </Button>
        <Button
          variant="unstyled"
          w={"100%"}
          borderRadius={0}
          borderRight={"1px solid"}
          borderLeft={"1px solid"}
          borderColor={"blueC.300"}
          color={"whiteC.100"}
          fontSize={"15px"}
          fontWeight={"600"}
          display={"flex"}
          gap={"6px"}
          onClick={() => setShowFilters(!showFilters)}
          position={"relative"}
          zIndex={100}
          bg={"blackC.800"}
        >
          <FilterByIcon w={"15px"} h={"15px"} />
          Filter By
        </Button>
        <Button
          variant="unstyled"
          w={"100%"}
          color={"whiteC.100"}
          fontSize={"15px"}
          fontWeight={"600"}
          borderRadius={0}
          display={"flex"}
          gap={"6px"}
          onClick={() => setShowLocation(!showLocation)}
          position={"relative"}
          zIndex={100}
          bg={"blackC.800"}
        >
          <LocationIcon w={"15px"} h={"15px"} />
          {showShipTo}
        </Button>
      </Flex>

      <QuickNavigateMobile
        showNavigate={showNavigate}
        handleChangeStatus={setShowNavigate}
      />

      <MobileMenuWrapper
        openStatus={showLocation}
        title={"Ship To"}
        handleChangeStatus={setShowLocation}
      >
        <Box px={5} py={"25px"} w={"100%"}>
          <ShipTo setShowLocation={setShowLocation} showLocation={showLocation} />
        </Box>
      </MobileMenuWrapper>

      <MobileMenuWrapper
        openStatus={showFilters}
        title={"Filter by"}
        handleChangeStatus={setShowFilters}
      >
        {filterInfo && filtersLoadingStatus === "idle" && (
          <Box pt={"14px"} px={"20px"}>
            <SelectedFilters />
          </Box>
        )}
        {filterInfo && filtersLoadingStatus === "idle" ? (
          <FiltersWrapper filtersData={filterInfo} />
        ) : (
          <FilterSkeletonMobile />
        )}
      </MobileMenuWrapper>
    </Flex>
  );
};
export default memo(FiltersMobile);
