import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import {
  CIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YouTubeIcon,
} from "../../assets/icons/icons";
import {Link as RouterLink, useLocation} from "react-router-dom";
import { getCurrentColor } from "../../constants";

const quickLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About",
    link: "/about-us",
  },
  {
    name: "FAQs",
    link: "/faq",
  },
  {
    name: "Contact Us",
    link: "#contact",
  },
  {
    name: "Retailers",
    link: "/registration",
  },
];

const typeOfAmmo = [
  { name: "Handgun Ammo", link: "/hangun-ammo" },
  { name: "Rifle Ammo", link: "/rifle-ammo" },
  { name: "Shotgun Ammo", link: "/shotgun-ammo" },
  { name: "Rimfire Ammo", link: "/rimfire-ammo" },
];

const constactUs = [
  { name: "For Retailers:", email: "nathaniel@bulletscout.com" },
  { name: "For General Info:", email: "info@bulletscout.com" },
];
const Footer = () => {
  const { colorMode } = useColorMode();

  const location = useLocation();

  return (
    <Box
      bg={getCurrentColor(colorMode, "blueC.600", "grayC.1700")}
      pb={{ base: "57px", lg: 0 }}
    >
      <Flex
        maxW={"1240px"}
        w={"100%"}
        mx={"auto"}
        px={{ base: "22px", xl: "10px" }}
        justifyContent={{ base: "center", xl: "space-between" }}
        flexDirection={{ base: "column", md: "row" }}
        flexWrap={"wrap"}
        gap={5}
        py={10}
      >
        <Flex
          direction={"column"}
          maxW={{ base: "auto", lg: "360px" }}
          w={{ base: "100%", md: "48%", lg: "auto" }}
          mb={{ base: 10, md: 0 }}
        >
          <Heading
            as="h2"
            fontSize={"22px"}
            color={"whiteC.100"}
            fontFamily={"mainFont"}
            fontWeight={"600"}
            mb={3}
          >
            <Image
              maxW={"108px"}
              src="https://cdn.bulletscout.com/image/logo-light.png"
              alt="logo footer"
              w={"100%"}
              h={"100%"}
            />
          </Heading>
          <Text mb={5} color={"whiteC.100"} fontSize={"sm"}>
            With years of experience in the industry, we've built a reputation
            for excellence, offering a selection of high-quality ammunition to
            meet your needs.
          </Text>
          <Flex gap={4} alignItems={"flex-end"} mt={"auto"}>
            <Text color={"whiteC.100"} fontWeight={"600"} fontSize={"sm"}>
              Follow Us :
            </Text>
            <Flex gap={4}>
              <Link as={RouterLink} to="#">
                <InstagramIcon color={"whiteC.100"} />
              </Link>
              <Link as={RouterLink} to="#">
                <YouTubeIcon color={"whiteC.100"} />
              </Link>
              <Link as={RouterLink} to="#">
                <FacebookIcon color={"whiteC.100"} />
              </Link>
              <Link as={RouterLink} to="#">
                <TwitterIcon color={"whiteC.100"} />
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap={{ xl: 28, lg: 20, base: 13 }}
          w={{ base: "100%", md: "48%", lg: "auto" }}
          justifyContent={"space-between"}
          mb={{ base: 10, md: 0 }}
        >
          <Box>
            <Heading
              as={"h3"}
              fontSize={"md"}
              color={"whiteC.100"}
              fontWeight={"500"}
              mb={5}
            >
              Quick Links
            </Heading>
            <List display={"flex"} flexDirection={"column"} gap={"10px"}>
              {quickLinks.map((link) => (
                <ListItem key={link.name}>
                  <Link
                    as={RouterLink}
                    to={location.pathname === '/' ? link.link : link.link === '#contact' ? `/${link.link}` : link.link}
                    fontSize={"sm"}
                    color={getCurrentColor(
                      colorMode,
                      "whiteC.100",
                      "grayC.1600",
                    )}
                    _hover={{ textDecoration: "none" }}
                  >
                    {link.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box>
            <Heading
              as={"h3"}
              fontSize={"md"}
              color={"whiteC.100"}
              fontWeight={"500"}
              mb={5}
            >
              Types of Ammo
            </Heading>
            <List display={"flex"} flexDirection={"column"} gap={"10px"}>
              {typeOfAmmo.map((type) => (
                <ListItem key={type.name}>
                  <Link
                    as={RouterLink}
                    to={type.link}
                    fontSize={"sm"}
                    _hover={{ textDecoration: "none" }}
                    color={getCurrentColor(
                      colorMode,
                      "whiteC.100",
                      "grayC.1600",
                    )}
                  >
                    {type.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        </Flex>

        <Flex flexDirection={"column"}>
          <Heading
            as={"h3"}
            fontSize={"md"}
            color={"whiteC.100"}
            fontWeight={"500"}
            mb={5}
          >
            Contact Us
          </Heading>
          <Flex gap={6}>
            <List display={"flex"} flexDirection={"column"} gap={"10px"}>
              {constactUs.map((contact) => (
                <ListItem key={contact.name}>
                  <Text
                    fontSize={"sm"}
                    color={getCurrentColor(
                      colorMode,
                      "whiteC.100",
                      "grayC.1600",
                    )}
                    mr={4}
                  >
                    {contact.name}
                  </Text>
                </ListItem>
              ))}
            </List>

            <List display={"flex"} flexDirection={"column"} gap={"10px"}>
              {constactUs.map((contact) => (
                <ListItem
                  display={"flex"}
                  key={contact.name}
                  justifyContent={"space-between"}
                >
                  <Link
                    as={RouterLink}
                    to={`mailto:${contact.email}`}
                    fontSize={"sm"}
                    color={getCurrentColor(
                      colorMode,
                      "whiteC.100",
                      "grayC.1600",
                    )}
                    _hover={{ textDecoration: "none" }}
                  >
                    {contact.email}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        py={3}
        w={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        bg={getCurrentColor(colorMode, "whiteC.200", "blueC.500")}
        fontSize={"sm"}
        color={getCurrentColor(colorMode, "grayC.1900", "grayC.2300")}
      >
        <CIcon /> 2024 Bullet Scout. All Rights Reserved
      </Flex>
    </Box>
  );
};
export default Footer;
