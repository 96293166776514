import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select as SingleSelect,
  Switch,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import {
  Select
} from 'chakra-react-select'
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { getCurrentColor } from "../../constants";
import useSearchParamsHandler from "../../hooks/useSearchParamsHandler";
import {
  categoriesPageModeAtom,
  roundsAtom as listingsRoundsAtom,
  shippingAtom as listingsShippingAtom,
  sortByAtom as listingsSortByAtom,
} from "../../atom/listings";
import {
  roundsAtom as productsRoundsAtom,
  shippingAtom as productsShippingAtom,
  sortByAtom as productsSortByAtom,
} from "../../atom/products";
import {
  filterInfoAtom,
  roundsAtom as groupListingsRoundsAtom,
  shippingAtom as groupListingsShippingAtom,
}
from "../../atom/groupListings"
import { useAtom } from "jotai";
import {shippingName} from "../../utils/shippingName";

const SelectSort = ({ slider }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { colorMode } = useColorMode();
  const { params, clearParams } = useSearchParamsHandler();
  const [categoriesPageMode, setCategoriesPageMode] = useAtom(categoriesPageModeAtom);

  const handleCategoriesPageModeToggle = () => {
    const newMode = categoriesPageMode === 'listings' ? 'products' : 'listings';
    setCategoriesPageMode(newMode);
    localStorage.setItem("categoriesPageMode", newMode);
  }

  const isListingsMode = categoriesPageMode === "listings";
  const [sortBy, setSortBy] = useAtom(isListingsMode ? listingsSortByAtom : productsSortByAtom);
  const [rounds, setRounds] = useAtom(slider ? groupListingsRoundsAtom : isListingsMode ? listingsRoundsAtom : productsRoundsAtom);
  const [shipping, setShipping] = useAtom(slider ? groupListingsShippingAtom : isListingsMode ? listingsShippingAtom : productsShippingAtom);
  const [, setRoundsSlider] = useAtom(groupListingsRoundsAtom);
  const [, setShippingSlider] = useAtom(groupListingsShippingAtom);
  const initialFocusRef = useRef(null);
  const [filters] = useAtom(filterInfoAtom);


  useEffect(() => {
    if (searchParams.get("sort_by") !== sortBy) {
      setSearchParams({ ...params, sort_by: sortBy });
    }

    if(!slider){
      setRoundsSlider('')
      setShippingSlider('')
    }

    if (slider) {
      const roundsQueryValue = rounds ? rounds.join(",") : "";
      const shippingQueryValue = shipping ? shipping.join(",") : "";

      if (roundsQueryValue && searchParams.get("rpb") !== roundsQueryValue) {
        setSearchParams({ ...params, rpb: roundsQueryValue });
      }

      if (shippingQueryValue && searchParams.get("sf") !== shippingQueryValue) {
        setSearchParams({ ...params, sf: shippingQueryValue });
      }

      if (!roundsQueryValue && !shippingQueryValue && (searchParams.get("rpb") || searchParams.get("sf"))) {
        setSearchParams({ sort_by: sortBy, page: params.page, pgId: params.pgId });
      }

      if (!roundsQueryValue && shippingQueryValue && searchParams.get("rpb")) {
        setSearchParams({ sort_by: sortBy, sf: shippingQueryValue, page: params.page, pgId: params.pgId });
      }

      if (roundsQueryValue && !shippingQueryValue && searchParams.get("sf")) {
        setSearchParams({ sort_by: sortBy, rpb: roundsQueryValue, page: params.page, pgId: params.pgId });
      }
    }
    // eslint-disable-next-line
  }, [sortBy, rounds, shipping]);

  return (
    <Flex gap={2}>
      <>
        {slider && (
          <>
            <Box
              position={"relative"}
              border={"1px solid"}
              borderColor={"blackC.600"}
              borderRadius={"6px"}
              minH={58}
              py={{ base: "0px", md: "8px" }}
              bg={"transparent"}
              cursor={"pointer"}
              display={{ base: "none", md: "block" }}
            >
              {filters &&
                  <Select
                      isMulti
                      cursor={"pointer"}
                      border={"none"}
                      _focus={{ outline: "none" }}
                      p={0}
                      pr={"20px"}
                      maxW={"190px"}
                      w={"100%"}
                      position={"relative"}
                      top={"7px"}
                      bg={"transparent"}
                      fontSize={{ base: "10", "1xs": "sm" }}
                      variant={"withoutIcon"}
                      placeholder={"Rounds"}
                      onChange={(selectedOptions) =>
                          setRounds(selectedOptions.map((option) => option.value))
                      }
                      value={
                        rounds
                            ? rounds.map((round) => ({ value: round, label: round }))
                            : []
                      }
                      options={Object.values(filters.rdsperbox).map((item) => ({
                        value: item.name,
                        label: item.name,
                      })).sort((a, b) => a.value - b.value)}
                      chakraStyles={{
                        placeholder: (provided) => ({
                          ...provided,
                          color: getCurrentColor(colorMode, "blackC.100", "whiteC.200")
                        }),
                      }}
                  />
              }
            </Box>

            <Box
              position={"relative"}
              border={"1px solid"}
              borderColor={"blackC.600"}
              borderRadius={"6px"}
              py={{ base: "0px", md: "8px" }}
              bg={"transparent"}
              cursor={"pointer"}
              display={{ base: "none", md: "block" }}
            >
              {filters &&
                  <Select
                      isMulti
                      cursor={"pointer"}
                      border={"none"}
                      _focus={{ outline: "none" }}
                      p={0}
                      pr={"20px"}
                      maxW={"190px"}
                      w={"100%"}
                      position={"relative"}
                      top={"7px"}
                      bg={"transparent"}
                      fontSize={{ base: "10", "1xs": "sm" }}
                      variant={"withoutIcon"}
                      icon={"none"}
                      placeholder={"Shipping"}
                      onChange={(selectedOptions) =>
                          setShipping(selectedOptions.map((option) => option.value))
                      }
                      value={
                        shipping
                            ? shipping.map((item) => ({ value: item, label: shippingName(item)}))
                            : []
                      }
                      options={Object.values(filters.ship_flag).map((item) => ({
                        value: item.name,
                        label: shippingName(item.name),
                      }))}
                      chakraStyles={{
                        placeholder: (provided) => ({
                          ...provided,
                          color: getCurrentColor(colorMode, "blackC.100", "whiteC.200"),
                        }),
                        container: (provided) => ({
                          ...provided,
                          position: "relative",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: 98,
                        })
                      }}
                  />
              }
            </Box>

            <Box display={{ base: "block", md: "none" }}>
              <Menu
                closeOnSelect={false}
                initialFocusRef={initialFocusRef}
                maxW={"194px"}
              >
                <MenuButton
                  as={Button}
                  border={"1px solid"}
                  borderColor={"blackC.600"}
                  borderRadius={"6px"}
                  px={3}
                  bg={"transparent"}
                  whiteSpace={"nowrap"}
                  h={"100%"}
                  _focus={{ background: "transparent" }}
                  _active={{ background: "transparent" }}
                  rightIcon={
                    <ChevronDownIcon
                      w={"20px"}
                      h={"20px"}
                      color={"grayC.1600"}
                    />
                  }
                >
                  <Text
                    as={"span"}
                    fontSize={"xs"}
                    fontFamily={"mainFont"}
                    color={getCurrentColor(
                      colorMode,
                      "grayC.100",
                      "grayC.1600",
                    )}
                    pr={2}
                  >
                    Filter by
                  </Text>
                </MenuButton>

                <MenuList
                  autoFocus={false}
                  bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
                  minW="0"
                  w="194px"
                >
                  <MenuItem p={0} bg={"transparent"}>
                    <Box width="100%">
                      {filters &&
                          <Select
                              isMulti
                              _focus={{ outline: "none" }}
                              border={"none"}
                              placeholder={"Rounds"}
                              onChange={(selectedOptions) =>
                                  setRounds(selectedOptions.map((option) => option.value))
                              }
                              value={
                                rounds
                                    ? rounds.map((round) => ({ value: round, label: round }))
                                    : []
                              }
                              options={Object.values(filters.rdsperbox).map((item) => ({
                                value: item.name,
                                label: item.name,
                              })).sort((a, b) => a.value - b.value)}
                              chakraStyles={{
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: getCurrentColor(colorMode, "blackC.100", "whiteC.200")
                                }),
                              }}
                          />
                      }
                    </Box>
                  </MenuItem>
                  <MenuItem p={0} bg={"transparent"}>
                    <Box width="100%">
                      {filters &&
                          <Select
                              isMulti
                              _focus={{ outline: "none" }}
                              border={"none"}
                              placeholder={"Shipping"}
                              onChange={(selectedOptions) =>
                                  setShipping(selectedOptions.map((option) => option.value))
                              }
                              value={
                                shipping
                                    ? shipping.map((item) => ({ value: item, label: shippingName(item)}))
                                    : []
                              }
                              options={Object.values(filters.ship_flag).map((item) => ({
                                value: item.name,
                                label: shippingName(item.name),
                              }))}
                              chakraStyles={{
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: getCurrentColor(colorMode, "blackC.100", "whiteC.200")
                                }),
                              }}
                          />
                      }
                    </Box>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </>
        )}
      </>

      {!slider &&
          <Flex display={{ base: "none", xl: "flex" }} alignItems={"end"} gap={2} mr={2}>
            <Text
                cursor={"pointer"}
                onClick={handleCategoriesPageModeToggle}
                ml={2}
                display={"flex"}
                alignItems={"center"}
                fontWeight={500}
                fontSize={"15px"}
                color={getCurrentColor(colorMode, categoriesPageMode === "products" ? "grayC.2300" : "blackC.100", categoriesPageMode === "products" ? "grayC.2300" :"whiteC.1600")}
            >
              Listings
            </Text>
            <Switch
                colorScheme="blue"
                isChecked={categoriesPageMode === "products"}
                onChange={handleCategoriesPageModeToggle}
            />
            <Text
                cursor={"pointer"}
                onClick={handleCategoriesPageModeToggle}
                display={"flex"}
                alignItems={"center"}
                fontWeight={500}
                fontSize={"15px"}
                color={getCurrentColor(colorMode, categoriesPageMode !== "products" ? "grayC.2300" : "blackC.100", categoriesPageMode !== "products" ? "grayC.2300" : "whiteC.100")}
            >
              Products
            </Text>
          </Flex>
      }

      <Box
        position={"relative"}
        border={"1px solid"}
        borderColor={"blackC.600"}
        borderRadius={"6px"}
        py={{ base: "0px", md: "8px" }}
        bg={"transparent"}
        cursor={"pointer"}
      >
        <Box
          position={"relative"}
          top={'50%'}
          transform="translateY(-50%)"
        >
          <Text
              as={"span"}
              position={"absolute"}
              fontSize={"xs"}
              fontFamily={"mainFont"}
              color={getCurrentColor(colorMode, "grayC.300", "whiteC.100")}
              left={"14px"}
              display={{ base: "none", md: "block" }}
              cursor={"pointer"}
          >
            Sort by
          </Text>

          <ChevronDownIcon
              color={"grayC.1600"}
              position={"absolute"}
              top={"50%"}
              transform={"translateY(-50%)"}
              right={"14px"}
              display={{ base: "none", md: "block" }}
              w={"20px"}
              h={"20px"}
              cursor={"pointer"}
          />
          <SingleSelect
              cursor={"pointer"}
              border={"none"}
              _focus={{ outline: "none" }}
              px={0}
              p={0}
              pr={"20px"}
              maxW={"190px"}
              w={"100%"}
              position={"relative"}
              top={"7px"}
              bg={"transparent"}
              fontSize={{ base: "10", "1xs": "sm" }}
              display={{ base: "none", md: "block" }}
              variant={"withoutIcon"}
              icon={"none"}
              onChange={({ target }) => setSortBy(target.value)}
              value={sortBy}
          >
            <option value="landed_cpr">Total CPR</option>
            <option value="cpr">Item CPR</option>
          </SingleSelect>
          <SingleSelect
              border={"none"}
              _focus={{ outline: "none" }}
              px={0}
              p={0}
              maxW={"190px"}
              w={"100%"}
              position={"relative"}
              bg={"transparent"}
              fontSize={{ base: "10", "1xs": "sm" }}
              display={{ base: "block", md: "none" }}
              placeholder="Sort by"
          >
            <option value="landed_cpr">Total CPR</option>
            <option value="cpr">Item CPR</option>
          </SingleSelect>
        </Box>
      </Box>
    </Flex>
  );
};

export default SelectSort;
