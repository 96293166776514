import {
  Flex,
  Grid,
  Heading,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { getCurrentColor } from "../../constants";

const data = [
  {
    title: "Featured Brands",
    description: "Discover premium ammunition from industry-leading brands.",
    logos: [
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Aguila_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Aguila_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Armscor_B_A.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Armscor_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Barnes_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Barnes_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Blazer_B_A.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Blazer_W_A.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Federal_C_A.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Federal_W_A.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Fiocchi_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Fiocchi_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Magtech_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Magtech_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/PMC_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/PMC_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Remington_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Remington_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Sig Sauer_B_A.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Sig Sauer_W_A.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Speer_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Speer_W.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Tulammo_C.png",
        black: "https://d337jieq2masmw.cloudfront.net/2024-08-12/Tulammo_W.png",
      },
    ],
  },
  {
    title: "Featured Vendors",
    description:
      "Shop with confidence from trusted ammunition vendors and suppliers.",
    logos: [
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/2NY_Black_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/2NY_White_W%20Art.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/American%20Marksman_Colored.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/American%20Marksman_White.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Ammo%20Hero_Black_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Ammo%20Hero_White_W%20Art.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Bass%20Pro%20Shops_Colored_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Bass%20Pro%20Shops_White_W%20Art.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Billet%20Bob_C_A.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Billet%20Bob_W_A.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Blackout%20Club_Colored_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Blackout%20Club_White_W%20Art.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Cabelas_Colored.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Cabelas_White.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Gun%20Prime_Black_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Gun%20Prime_White_W%20Art.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/KIR%20AMMO_Colored_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/KIR%20AMMO_White_W%20Art.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Palmetto_Colored_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Palmetto_White_W%20Art.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/PNR%20Ammo_C_A.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/PNR%20Ammo_W_A.png",
      },
      {
        white: "https://d337jieq2masmw.cloudfront.net/light_mode_vendors/Saint%20Barbs%20Bullets_Black_W%20Art.png",
        black: "https://d337jieq2masmw.cloudfront.net/Dark_mode_vendors/Saint%20Barbs%20Bullets_White.png",
      },
    ],
  },
];

const MainPageSectionBrands = () => {
  const { colorMode } = useColorMode();
  return data.map((items) => {
    return (
      <Flex
        flexDirection={"column"}
        maxW={"1240px"}
        w={"100%"}
        key={items.title}
        mb={{ base: "50px", lg: "60px" }}
        mx={"auto"}
        px={{ base: "22px", ".5xl": "0px" }}
      >
        <Heading
          mb={2}
          fontFamily={"mainFont"}
          fontSize={{ base: "25px", md: "3xl" }}
          color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
        >
          {items.title}
        </Heading>
        <Text
          mb={{ base: 6, md: "30px" }}
          fontFamily={"mainFont"}
          fontSize={"lg"}
          color={getCurrentColor(colorMode, "grayC.100", "grayC.1600")}
        >
          {items.description}
        </Text>
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            xl: "repeat(4, 1fr)",
            ".5xl": "repeat(6, 1fr)",
          }}
          gap={"10px"}
          mx={"auto"}
        >
          {items.logos.map((logo, i) => (
            <Flex
              justifyContent={"center"}
              key={i}
              alignItems={"center"}
              w={{ base: "150px", "1xs": "185px", lg: "198px" }}
              h={{ base: "76px", lg: "82px" }}
              borderRadius={"5px"}
              border={"1px solid "}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.900",
                "grayC.2400",
              )}
              boxShadow={
                "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
              }
              bg={getCurrentColor(colorMode, "whiteC.100", "transparent")}
              padding='15px'
            >
              <Image objectFit={'scale-down'} w={"auto"} h={{ base: "70px", lg: "76px" }} loading="lazy" src={colorMode === 'dark' ? logo.black : logo.white} alt={logo.white} />
            </Flex>
          ))}
        </Grid>
      </Flex>
    );
  });
};

export default MainPageSectionBrands;
